let mapConfig = {
  // maptiler: {
  //   attr: "https://api.maptiler.com/maps/topo-v2/style.json?key=7L8fxmeOsR4BgcUZ7SHL",
  //   url: "https://api.maptiler.com/maps/topo-v2/256/{z}/{x}/{y}.png?key=7L8fxmeOsR4BgcUZ7SHL",
  // },
  maptiler: {
    attr: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    url: "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
  },
};

export default mapConfig;