import { Typography } from "antd";

const { Title, Text } = Typography;
const NotFoundPage = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        marginTop: "200px",
      }}
    >
      <Title
        style={{
          padding: "0",
          margin: "0",
          color: "black",
          fontWeight: "600",
          fontSize: "5rem",
        }}
      >
        404
      </Title>
      <Title style={{ marginTop: "0", color: "blue" }}>Page Not Found</Title>
      <Text style={{ marginTop: "0", color: "black" }}>
        Kindly check the url and try again
      </Text>
    </div>
  );
};

export default NotFoundPage;
