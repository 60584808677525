import { useLeafletContext } from '@react-leaflet/core';
import L from 'leaflet';
import { useEffect, useRef } from 'react';
import { categoryColor } from "../../../models/CoreTypes";
import './legend.css';

export function Legend() {

    const context = useLeafletContext();
    let legend =  new L.Control({ position: "bottomright" });
    const legendRef = useRef(legend);
   // const propsRef = useRef(categoryColorMap);

    useEffect(() => {
      //legendRef.current =  new L.Control({ position: "bottomright" });

      legendRef.current.onAdd = () => {
        const div = L.DomUtil.create("div", "info legend");

        let categoryColorMap = categoryColor.getColorMap();
        // loop through our density intervals and generate a label with a colored square for each interval
        categoryColorMap.forEach((val: any, key: any) => {
          div.innerHTML +=
            //'<LegendIcon color="' + val + '" /> <br>' +
            '<i class="Circle1" style="background:' +
            val +
            '"></i> <i class="Circle2"></i> <br>' +
            "<b>" +
            key +
            "</b><br><br>";
        });

        return div;
      };

      const container = context.map;
      container.addControl(legendRef.current);

      return () => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        container.removeControl(legendRef.current);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //   useEffect(() => {
    //     if (
    //         categoryColorMap.center !== propsRef.current.center ||
    //       props.size !== propsRef.current.size
    //     ) {
    //       squareRef.current.setBounds(getBounds(props))
    //     }
    //     propsRef.current = props
    //   }, [props.center, props.size]);
    
      return null;

    };

    export function LegendIcon(props : any) {
      return (
        <>
        <i className="Circle1" style={{ background: props.Color}} ></i> 
        {/* <i className="Circle2"></i> */}
        </>
      );
    }

    