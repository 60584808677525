import { createContext, useContext, useState } from "react";

export const MenuContext = createContext<any>({
  expanded: false,
  setExpanded: () => {},
});

const MenuContextProvider = ({ children }: any) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <MenuContext.Provider value={{ expanded, setExpanded }}>
      {children}
    </MenuContext.Provider>
  );
};

export const useMenuContext = () => useContext(MenuContext);

export default MenuContextProvider;
