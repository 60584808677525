
export enum MarkerType {
    Sensor = 0,
    Gateway
}

export enum SensorMetricCondition {
    Good = 1,
    Weak = 2,
    Okay = 4,
    Hr24SinceReported = 8,
    Hr2SinceReported = 16,
    Active = 32,
    Inactive = 64
}

export enum SensorMetricType {
    RSSI = 0,
    SNR,
    SpreadingFactor,
    Temperature
}

export interface IMarkerModel {
    icon: L.Icon;
    mrkrType: MarkerType;
    latLng: any;
    id: any;
    rssi: number;
    rssiMetricColor: string | undefined;
    snr: number;
    snrMetricColor: string | undefined;
    spreadingFactor: number;
    owner: string;
    reportedAt: string | Date;
    sensorReported: Date;
    prettyDate: string;
    name: string;
    rssiMetricCondition: SensorMetricCondition | undefined;
    snrMetricCondition: SensorMetricCondition | undefined;
    isActive : boolean;
    historicRSSIvalueMap: Map<string, number> | undefined;
    historicSNRvalueMap: Map<string, number> | undefined;
    getRSSIColorByValue: (rssiVal: number) => string | undefined;
    getSNRColorByValue: (snrVal: number) => string | undefined;
    get getRSSIMarkerModel(): IMarkerModel;
    get getSNRMarkerModel(): IMarkerModel;
}


export class SensorMetricCategory {
    static getRssiCondition(rssiVal: number, lastReportedAt: Date): SensorMetricCondition | undefined {
        let twoHours = 120 * 60 * 1000;
        let oneDay = 1440 * 60 * 1000;
        let currentTime: Date = new Date();
        let timeDiff = currentTime.valueOf() - lastReportedAt.valueOf();

        //Check if sensor didnt report in the last day
        if (timeDiff > oneDay) { return (SensorMetricCondition.Hr24SinceReported); }
        //Check if sensor didnt report in the last two hours
        else if (timeDiff > twoHours) { return (SensorMetricCondition.Hr2SinceReported); }
        // dynamic styling depending on RSSI value
        else if (rssiVal > -115) { return (SensorMetricCondition.Good); }
        else if (rssiVal <= -115 && rssiVal > -120) { return (SensorMetricCondition.Okay); }
        else if (rssiVal <= -120) { return (SensorMetricCondition.Weak); }
        else return undefined;
    }

    static getSnrCondition(snrVal: number, lastReportedAt: Date): SensorMetricCondition | undefined {
        let twoHours = 120 * 60 * 1000;
        let oneDay = 1440 * 60 * 1000;
        let currentTime: Date = new Date();
        let timeDiff = currentTime.valueOf() - lastReportedAt.valueOf();

        //Check if sensor didnt report in the last day
        if (timeDiff > oneDay) { return (SensorMetricCondition.Hr24SinceReported); }
        //Check if sensor didnt report in the last two hours
        else if (timeDiff > twoHours) { return (SensorMetricCondition.Hr2SinceReported); }
        // dynamic styling depending on RSSI value
        else if (snrVal > -7) { return (SensorMetricCondition.Good); }
        else if (snrVal <= -7 && snrVal > -13) { return (SensorMetricCondition.Okay); }
        else if (snrVal <= -13) { return (SensorMetricCondition.Weak); }
        else return undefined;
    }

    static getRssiConditionColor(rssiVal: number, lastReportedAt: Date): string | undefined {
        let rssiCondition = this.getRssiCondition(rssiVal, lastReportedAt);

        // 
        if(rssiCondition === undefined)
        {
            return categoryColor.getColorMap().get("Default");
        }
        else if((rssiCondition & SensorMetricCondition.Good) === SensorMetricCondition.Good)
        {
            return categoryColor.getColorMap().get("Strong signal");
        }
        else if ((rssiCondition & SensorMetricCondition.Okay) === SensorMetricCondition.Okay)
        {
            return categoryColor.getColorMap().get("OK signal");
        }
        else if ((rssiCondition & SensorMetricCondition.Weak) === SensorMetricCondition.Weak)
        {
            return categoryColor.getColorMap().get("Weak signal");
        }
        else if ((rssiCondition & SensorMetricCondition.Hr24SinceReported) === SensorMetricCondition.Hr24SinceReported)
        {
            return categoryColor.getColorMap().get(">24h since reported");
        }
        else if ((rssiCondition & SensorMetricCondition.Hr2SinceReported) === SensorMetricCondition.Hr2SinceReported)
        {
            return categoryColor.getColorMap().get(">2h since reported");
        }
        else
        {
            return categoryColor.getColorMap().get("Default");
        }
    }

    static getSnrConditionColor(rssiVal: number, lastReportedAt: Date): string | undefined {
        let snrCondition = this.getSnrCondition(rssiVal, lastReportedAt);

        // 
        if(snrCondition === undefined)
        {
            return categoryColor.getColorMap().get("Default");
        }
        else if((snrCondition & SensorMetricCondition.Good) === SensorMetricCondition.Good)
        {
            return categoryColor.getColorMap().get("Strong signal");
        }
        else if ((snrCondition & SensorMetricCondition.Okay) === SensorMetricCondition.Okay)
        {
            return categoryColor.getColorMap().get("OK signal");
        }
        else if ((snrCondition & SensorMetricCondition.Weak) === SensorMetricCondition.Weak)
        {
            return categoryColor.getColorMap().get("Weak signal");
        }
        else if ((snrCondition & SensorMetricCondition.Hr24SinceReported) === SensorMetricCondition.Hr24SinceReported)
        {
            return categoryColor.getColorMap().get(">24h since reported");
        }
        else if ((snrCondition & SensorMetricCondition.Hr2SinceReported) === SensorMetricCondition.Hr2SinceReported)
        {
            return categoryColor.getColorMap().get(">2h since reported");
        }
        else
        {
            return categoryColor.getColorMap().get("Default");
        }
    }
}

export class categoryColor {
    static getColorMap(): Map<String, string> {
        let categoryColorMap: Map<String, string>;
        categoryColorMap = new Map<String, string>();
        categoryColorMap.set("Strong signal", "#2AAD27");
        categoryColorMap.set("OK signal", "#FFD326");
        categoryColorMap.set("Weak signal", "#CB2B3E");
        categoryColorMap.set(">2h since reported", "#7B7B7B");
        categoryColorMap.set(">24h since reported", "#000000");
        categoryColorMap.set("Gateways", "#2A81CB");
        categoryColorMap.set("Default", "#FFEDA0");

        return categoryColorMap;
    }
}