import {
    Title,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeaderCell,
    TableRow,
} from "@tremor/react";
import { SensorMetrics } from "../models/deviceStatistics";
import { categoryColor, SensorMetricType } from "../../../models/CoreTypes";
import _ from "lodash";

const SensorSummary = (props: any) => {

    function getSensorSummaryObj() {
        let sensorSummaryObj = {
            company: "",
            pcnt_inactive: 0,
            pcnt_weak_rssi: 0,
            pcnt_okay_rssi: 0,
            pcnt_good_rssi: 0,
            pcnt_weak_snr: 0,
            pcnt_okay_snr: 0,
            pcnt_good_snr: 0,
            total_devices: 0
        }
        let sensorSummaryArr: any = [];

        props.sensorMetrics.forEach((valueArr: SensorMetrics[], key: string, map: any) => {
            sensorSummaryObj.company = key;
            valueArr.forEach(v => {
                if (v.metricType === SensorMetricType.RSSI) {
                    sensorSummaryObj.pcnt_good_rssi = v.good_pcnt;
                    sensorSummaryObj.pcnt_okay_rssi = v.okay_pcnt;
                    sensorSummaryObj.pcnt_weak_rssi = v.weak_pcnt;
                    sensorSummaryObj.total_devices = v.total_devices;
                    sensorSummaryObj.pcnt_inactive = v.inactive_pcnt;
                }
                else if (v.metricType === SensorMetricType.SNR) {
                    sensorSummaryObj.pcnt_weak_snr = v.weak_pcnt;
                    sensorSummaryObj.pcnt_okay_snr = v.okay_pcnt;
                    sensorSummaryObj.pcnt_good_snr = v.good_pcnt;
                }
            });
            sensorSummaryArr.push(_.clone(sensorSummaryObj));
        });

        return sensorSummaryArr;
    }

    return (
        <>
            <Title >Sensor Summary By Customer</Title>
            <Table className="mt-6">
                <TableHead>
                    <TableRow>
                        <TableHeaderCell>Customer</TableHeaderCell>
                        <TableHeaderCell className="text-right">
                            Inactive (%)
                        </TableHeaderCell>
                        <TableHeaderCell className="text-right">
                            Weak RSSI (%)
                        </TableHeaderCell>
                        <TableHeaderCell className="text-right">
                            Okay RSSI (%)
                        </TableHeaderCell>
                        <TableHeaderCell className="text-right">
                            Good RSSI (%)
                        </TableHeaderCell>
                        <TableHeaderCell className="text-right">
                            Weak SNR (%)
                        </TableHeaderCell>
                        <TableHeaderCell className="text-right">
                            Okay SNR (%)
                        </TableHeaderCell>
                        <TableHeaderCell className="text-right">
                            Good SNR (%)
                        </TableHeaderCell>
                        <TableHeaderCell className="text-right">Total Devices</TableHeaderCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {getSensorSummaryObj().map((s: any) => (
                        <TableRow key={s.company}>
                            <TableCell>{s.company}</TableCell>
                            <TableCell style={{
                                backgroundColor:categoryColor.getColorMap().get(">24h since reported"),
                                color:"white",
                                fontWeight:"bolder"}}
                                className="text-right">
                                {s.pcnt_inactive}
                            </TableCell>
                            <TableCell style={{
                                backgroundColor:categoryColor.getColorMap().get("Weak signal"), color:"white",fontWeight:"bolder"}} className="text-right">{s.pcnt_weak_rssi}</TableCell>
                            <TableCell style={{backgroundColor:categoryColor.getColorMap().get("OK signal"), color:"white",fontWeight:"bolder"}} className="text-right">{s.pcnt_okay_rssi}</TableCell>
                            <TableCell style={{backgroundColor:categoryColor.getColorMap().get("Strong signal"), color:"white",fontWeight:"bolder"}} className="text-right">{s.pcnt_good_rssi}</TableCell>
                            <TableCell style={{backgroundColor:categoryColor.getColorMap().get("Weak signal"), color:"white",fontWeight:"bolder"}} className="text-right">{s.pcnt_weak_snr}</TableCell>
                            <TableCell style={{backgroundColor:categoryColor.getColorMap().get("OK signal"), color:"white",fontWeight:"bolder"}} className="text-right">{s.pcnt_okay_snr}</TableCell>
                            <TableCell style={{backgroundColor:categoryColor.getColorMap().get("Strong signal"), color:"white",fontWeight:"bolder"}} className="text-right">{s.pcnt_good_snr}</TableCell>
                            <TableCell className="text-right">{s.total_devices}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    );
}

export default SensorSummary;