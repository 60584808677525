import { API, graphqlOperation } from "aws-amplify";
import { listDimDocks30s } from "../../graphql/queries";

export const ProjectAPI = {
  async getProjectByName(projectName: string, organizationId: string, userId: string) {
    /**
     * TODO: Remove organization Id from parameter and update the interface to get the data
     */
    const queryVariables = {
      limit: 5000000,
      filter: {
        pk: {
          eq: `ORGANIZATION#${organizationId}`,
        },
        ProjectName: {
          eq: projectName,
        },
        sk: {
          contains: "PROJECT",
          notContains: "SERVICE",
        },
      },
    };
    return new Promise(async (resolve, reject) => {
      try {
        const result = await API.graphql(
          graphqlOperation(listDimDocks30s, queryVariables)
        );
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  },
};
