import { theme } from "antd";

const Theme = {
  token: {
    colorPrimary: "#0461cf",
    colorSecondary: "#2194ff17",
    colorSuccess: "#20c997",
    colorWarning: "#fd7e14",
    colorError: "#dc3545",
    navBgPrimary: "#FCFCFC",
    navBorderPrimary: "#e2e0e5",
    navBorderSecondary: "#e2e0e5cc",
    navBorderTernary: "#e2e0e5bf",
    navFooterPrimary: "#f0f0f0",
    borderRadius: 5,
    wireframe: false,
    fontFamily: "'Poppins', sans-serif",
    colorText: "#1e1e1e",
  },
  app: {
    borderRadius: "5px",
    borderWidth: "1px",
    colorHeading: "#444343",
    spanColor: "#b6b6b6",
    colorInputLabel: "#BDBDBD",
    headerBg: "rgb(189 189 189 / 41%)",
    textColor: "#413F42",
    textColorLight: "#a79ea1",
    textColorSecondary: "#6c6768",
    backgroundColorLight: "#FCFCFC",
  },
  algorithm: theme.defaultAlgorithm,
};

export default Theme;
