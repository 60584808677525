import { Typography } from "antd";

const { Title } = Typography;
const UnauthenticatedPage = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        marginTop: "200px",
      }}
    >
      <Title
        style={{
          padding: "0",
          margin: "0",
          color: "black",
          fontWeight: "600",
          fontSize: "5rem",
        }}
      >
        401
      </Title>
      <Title style={{ marginTop: "0", color: "blue" }}>Not Authorized</Title>
    </div>
  );
};

export default UnauthenticatedPage;
