import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import { LegendIcon } from "./Legend";
import { MetricLineChart } from "./MetricLineChart";

const onChange = (key: string) => {
    console.log(key);
};

export function TabPopup(props: any) {

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: `Info`,
            children: <MarkerPopupInfo markerModel={props.markerProp} />,
            style: { height: 150 } 
        },
        {
            key: '2',
            label: `RSSI - Historical`,
            children: <MarkerPopupMetricHistory markerModel={props.markerProp} metricType="rssi" />,
            style: { height: 150 } 
        },
        {
            key: '3',
            label: `SNR - Historical`,
            children: <MarkerPopupMetricHistory markerModel={props.markerProp} metricType="snr" />,
            style: { height: 150 } 
        },
    ];

    return (
        <Tabs size={"small"} defaultActiveKey="1" items={items} onChange={onChange} />
    );
}

export function MarkerPopupMetricHistory(props: any) {

    return (
        <>
            {(props.metricType === "rssi") ? ((props.markerModel.historicRSSIvalueMap !== undefined) ?
                (<MetricLineChart metricType={props.metricType} markerModel={props.markerModel} dataValues={[...props.markerModel.historicRSSIvalueMap.entries()]} />)
                : <></>) : ((props.markerModel.historicSNRvalueMap !== undefined) ?
                    <MetricLineChart metricType={props.metricType} markerModel={props.markerModel} dataValues={[...props.markerModel.historicSNRvalueMap.entries()]} />
                    : <></>)
            }
        </>
    );
}

export function MarkerPopupInfo(props: any) {

    return (
        <>
            <b>Name:</b> {props.markerModel.name} <br />
            <b>Owner:</b> {props.markerModel.owner} <br />
            <b>Last reported:</b> {props.markerModel.prettyDate} <br />
            <b>RSSI:</b> {props.markerModel.rssi} <LegendIcon Color={props.markerModel.rssiMetricColor} /> <br />
            <b>SNR:</b> {props.markerModel.snr} <LegendIcon Color={props.markerModel.snrMetricColor} /> <br />
            <b>SpreadingFactor:</b> {props.markerModel.spreadingFactor} <br />
        </>
    );
}