import { CSSProperties } from "react";
import Theme from "../theme/Theme";

type CSSInterface = {
  [key: string]: CSSProperties;
};

const NavBarStyles: CSSInterface = {
  header: {
    backgroundColor: Theme.token.navBgPrimary,
    position: "relative",
    display: "flex",
    borderBottom: `1px solid ${Theme.token.navBorderPrimary}`,
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
    padding: "0.25rem 2rem",
    height: "70px",
    zIndex: "999",
  },
  menu: {
    backgroundColor: Theme.token.navBgPrimary,
    justifyContent: "center",
    borderBottom: Theme.token.navBorderPrimary,
    alignItems: "center",
    color: Theme.app.textColorSecondary,
    fontWeight: "400",
    fontSize: "1rem",
    width: "60%",
    height: "100%",
    borderRadius: "5px",
  },
  title: {
    padding: "0",
    margin: "0",
    display: "inline",
    lineHeight: "0",
    color: Theme.token.colorPrimary,
  },
  subTitle: {
    padding: "0",
    margin: "0",
    display: "inline",
    lineHeight: "0",
    opacity: "0.75",
  },
  accountContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "1rem",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    gap: "24px",
    alignItems: "flex-start",
  },
  navContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: "2rem",
  },
  headerSelector: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "1rem",
    cursor: "pointer",
  },
};

export const NavBarStylesMobile: CSSInterface = {
  navContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.5rem",
  },
  header: {
    backgroundColor: Theme.token.navBgPrimary,
    position: "relative",
    display: "flex",
    borderBottom: `1px solid ${Theme.token.navBorderPrimary}`,
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
    padding: "0.25rem",
    height: "70px",
    zIndex: "999",
  },
  headerSelector: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "0.5rem",
    cursor: "pointer",
  },
  accountContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "0.5rem",
  },
};

export default NavBarStyles;
