import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { IContextData } from "../utils/interface";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const RequireAuth = () => {
  const location = useLocation();
  const { username, isLoadingUser, userRole }: IContextData = useAuth();


  if (isLoadingUser) {
    return (
      <Spin
        style={{ position: "absolute", top: "50%", left: "50%" }}
        indicator={antIcon}
      />
    );
  }

  if (userRole && username) {
    return <Outlet />;
  }
  if (username) {
    // navigate("/");
    return <Navigate to="/401" state={{ from: location }} replace />;
  }

  return <Navigate to="/login" state={{ from: location }} replace />;
};

export default RequireAuth;
