import { MarkerStyles } from "./markerStyle";
import {IMarkerModel, MarkerType, SensorMetricCategory, SensorMetricCondition} from "../../../models/CoreTypes";

export class MarkerModel implements IMarkerModel {
    icon: L.Icon;
    mrkrType: MarkerType;
    latLng: any;
    id: any;
    rssi: number;
    rssiMetricColor: string | undefined;
    rssiMetricCondition: SensorMetricCondition | undefined;

    snr: number;
    snrMetricColor: string | undefined;
    snrMetricCondition : SensorMetricCondition | undefined;

    isActive : boolean;
    
    spreadingFactor: number;
    owner: string = "";
    reportedAt: string | Date;
    sensorReported: Date;
    prettyDate: string = "";
    name: string = "";
    historicRSSIvalueMap: Map<string, number> | undefined;
    historicSNRvalueMap: Map<string, number> | undefined;

    twoHours = 120 * 60 * 1000;
    oneDay = 1440 * 60 * 1000;


    constructor(markerType: MarkerType, latLng: any, id: any, rssi: number,
        snr: number, spreadingFactor: number, owner: string, reportedAt: string | Date, name: string,
        timeseries: {
            metric: string;
            value: number;
            mean: number;
            time: string;
        }[] | undefined) {

        this.mrkrType = markerType;
        this.latLng = latLng || [0, 0];
        this.id = id;
        this.rssi = rssi;
        this.snr = snr;
        this.spreadingFactor = spreadingFactor;
        this.reportedAt = reportedAt.toString();
        this.owner = (owner === undefined) ? 'Owner saknas i Yggio' : owner;
        this.name = JSON.stringify(name).replaceAll('"', "");
        this.sensorReported = new Date(this.reportedAt);
        let updated = this.sensorReported.toString();
        this.prettyDate = updated.substring(15, 21) + " " + updated.substring(4, 15);
        switch (this.mrkrType) {
            case MarkerType.Gateway: this.icon = MarkerStyles.GatewayIcon;
                break;
            case MarkerType.Sensor: this.icon = MarkerStyles.GreyIcon;
                break;
        }
        this.rssiMetricCondition = SensorMetricCategory.getRssiCondition(this.rssi, this.sensorReported);
        this.rssiMetricColor = this.getRSSIColorByValue(this.rssi);
        
        this.snrMetricCondition = SensorMetricCategory.getSnrCondition(this.snr,this.sensorReported);
        this.snrMetricColor = this.getSNRColorByValue(this.snr);

        this.isActive = this.isSensorActive();

        //Load timeseries data in map based on metric type
        if (timeseries !== undefined) {
            timeseries.forEach(t => {
                if (t.metric === "rssi") {
                    if(this.historicRSSIvalueMap === null || this.historicRSSIvalueMap === undefined)
                    {
                        this.historicRSSIvalueMap = new Map<string, number>();
                    }
                    this.historicRSSIvalueMap.set(t.time, t.value);
                    
                }
                else {
                    if(this.historicSNRvalueMap === null || this.historicSNRvalueMap === undefined)
                    {
                        this.historicSNRvalueMap = new Map<string, number>();
                    }
                    this.historicSNRvalueMap.set(t.time, t.value);
                }
            });
        }
    }

    private isSensorActive() : boolean {

        if(this.rssiMetricCondition === undefined || this.snrMetricCondition === undefined)
        {
            return false;
        }

        if((this.rssiMetricCondition & SensorMetricCondition.Hr24SinceReported) === SensorMetricCondition.Hr24SinceReported )
        {
            return false;
        }

        if((this.snrMetricCondition & SensorMetricCondition.Hr24SinceReported) === SensorMetricCondition.Hr24SinceReported )
        {
            return false;
        }

        return true;

    }

    public getRSSIColorByValue(rssiVal: number): string | undefined {
        // let currentTime: Date = new Date();
        // let timeDiff = currentTime.valueOf() - this.sensorReported.valueOf();

        // let categoryColorMap = categoryColor.getColorMap();

        return SensorMetricCategory.getRssiConditionColor(rssiVal, this.sensorReported);

        // if(rssiCondition === undefined)
        // {
        //     return categoryColorMap.get("Default");
        // }
        // else
        // {

        // }

        // //Check if sensor didnt report in the last day
        // if (timeDiff > this.oneDay) { return categoryColorMap.get(">24h since reported"); }
        // //Check if sensor didnt report in the last two hours
        // else if (timeDiff > this.twoHours) { return categoryColorMap.get(">2h since reported"); }
        // // dynamic styling depending on RSSI value
        // else if (rssiVal > -115) { return categoryColorMap.get("Strong signal"); }
        // else if (rssiVal <= -115 && this.rssi > -120) { return categoryColorMap.get("OK signal"); }
        // else if (rssiVal <= -120) { return categoryColorMap.get("Weak signal"); }
        // else return categoryColorMap.get("Default");
    }

    get getRSSIMarkerModel(): MarkerModel {

        const rssiCol = this.getRSSIColorByValue(this.rssi);
        if (rssiCol === undefined) {
            this.icon = MarkerStyles.DefaultIcon;
        }
        else {
            this.icon = MarkerStyles.getIcon(rssiCol);
        }

        return this;
    }

    public getSNRColorByValue(snrVal: number): string | undefined {
        // let currentTime: Date = new Date();
        // let timeDiff = currentTime.valueOf() - this.sensorReported.valueOf();

        
        return SensorMetricCategory.getSnrConditionColor(snrVal, this.sensorReported);

        // let categoryColorMap = categoryColor.getColorMap();

        // //Check if sensor didnt report in the last day
        // if (timeDiff > this.oneDay) { return categoryColorMap.get(">24h since reported"); }
        // //Check if sensor didnt report in the last two hours
        // else if (timeDiff > this.twoHours) { return categoryColorMap.get(">2h since reported"); }
        // // dynamic styling depending on RSSI value
        // else if (snrVal > -7) { return categoryColorMap.get("Strong signal"); }
        // else if (snrVal <= -7 && this.snr > -13) { return categoryColorMap.get("OK signal"); }
        // else if (snrVal <= -13) { return categoryColorMap.get("Weak signal"); }
        // else return categoryColorMap.get("Default");
    }

    get getSNRMarkerModel(): MarkerModel {

        const snrCol = this.getSNRColorByValue(this.snr);
        if (snrCol === undefined) {
            this.icon = MarkerStyles.DefaultIcon;
        }
        else {
            this.icon = MarkerStyles.getIcon(snrCol);
        }

        return this;
    }

}

