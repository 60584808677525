import React from "react";
import ReactDOM from "react-dom/client";
import "./theme/fonts.css";
import "./theme/main.css";
import App from "./App";
import { Authenticator } from "@aws-amplify/ui-react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import AuthContextProvider from "./context/AuthContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import MenuContextProvider from "./context/MenuContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <Authenticator.Provider>
          <AuthContextProvider>
            <MenuContextProvider>
              <Routes>
                <Route path="/*" element={<App />} />
              </Routes>
            </MenuContextProvider>
          </AuthContextProvider>
        </Authenticator.Provider>
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
