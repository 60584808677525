import { DashboardRounded } from "@mui/icons-material";
import MapIcon from "@mui/icons-material/Map";
import { Button, Layout, Menu } from "antd";
import type { MenuProps } from "antd/es/menu";
import { Amplify } from "aws-amplify";
import { useLocation, useNavigate } from "react-router-dom";
import "./App.css";
import NavBar from "./UI/NavBar";
import awsConfig from "./aws-exports";
import getIcon from "./components/icons/icons";
import { useAuth } from "./context/AuthContext";
import { useMenuContext } from "./context/MenuContext";
import RoutesProvider from "./routes/RoutesProvider";
import Theme from "./theme/Theme";
import { USER_ROLES } from "./utils/helpers";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key?: React.Key | null,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const { Content, Sider, Footer } = Layout;
const leftNavMenu: MenuItem[] = [
  getItem("Dashboard", "", <DashboardRounded fontSize="large" />),
  getItem("Signal Strength Map", "map", <MapIcon fontSize="large" />),
];

// init
Amplify.configure({
  ...awsConfig,
  cookieStorage: {
    domain:
      process.env.REACT_APP_DOMAIN === undefined
        ? ".dimdocks.com"
        : process.env.REACT_APP_DOMAIN,
    path: "/",
    expires: 1,
    secure: true,
  },
});

const App = () => {
  const navigate = useNavigate();
  const { username, userRole } = useAuth();
  const { pathname } = useLocation();
  const { expanded, isExpanded } = useMenuContext();

  return (
    <Layout
      style={{
        minHeight: "100vh",
        display: "flex",
      }}
    >
      <NavBar />
      <Layout>
        {username &&
          userRole &&
          userRole.length > 0 &&
          userRole !== USER_ROLES.reader && (
            <Sider
              trigger={null}
              collapsible
              collapsed={expanded}
              width={260}
              style={{
                borderRight: `1px solid ${Theme.token.navBorderPrimary}`,
                zIndex: "10",
              }}
              onCollapse={(value) => isExpanded(value)}
            >
              <Menu
                className="left-menu"
                style={{
                  backgroundColor: Theme.token.navBgPrimary,
                  height: "100%",
                  borderRight: 0,
                  padding: "1rem 0.5rem",
                  color: "black",
                  fontWeight: "600",
                }}
                defaultSelectedKeys={["map"]}
                items={leftNavMenu}
                onClick={(item) =>
                  navigate(
                    `/${pathname.split("/")[1]}/${pathname.split("/")[2]}/${
                      item.key
                    }`,
                    {
                      replace: true,
                    }
                  )
                }
              />
            </Sider>
          )}

        <Layout>
          <Content
            style={{
              backgroundColor: "white",
              padding: "0.5rem",
            }}
          >
            <RoutesProvider />
          </Content>
          <Footer
            style={{
              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
              backgroundColor: Theme.token.navFooterPrimary,
            }}
          >
            <Button
              href="https://www.wsp.com/en-gb"
              size="large"
              type="link"
              icon={getIcon("wsp-red-logo")}
            />
          </Footer>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default App;
