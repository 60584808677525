import { IMarkerModel, SensorMetricCondition, SensorMetricType } from "../../../models/CoreTypes";
import * as _ from 'lodash';

export class SensorMetrics {
    metricType: SensorMetricType = SensorMetricType.RSSI;

    good_pcnt: number = 0;
    okay_pcnt: number = 0;
    weak_pcnt: number = 0;

    total_devices: number = 0;
    active_pcnt: number = 0;
    inactive_pcnt: number = 0;

    constructor(metricTyp: SensorMetricType, pcntGood: number, pcntOk: number, pcntWeak: number, pcntActive: number, pcntInactive: number, totalDevices: number) {
        this.metricType = metricTyp;
        this.good_pcnt = pcntGood;
        this.okay_pcnt = pcntOk;
        this.weak_pcnt = pcntWeak;
        this.active_pcnt = pcntActive;
        this.inactive_pcnt = pcntInactive;
        this.total_devices = totalDevices;
    }
}

export class SensorStatistics {
    metricsByOwner: Map<string, SensorMetrics[]> = new Map<string, SensorMetrics[]>();

    constructor(sensors: IMarkerModel[]) {

        //Group sensors by owner(s)
        let ownerGroup = _.mapValues(_.groupBy(sensors, 'owner'),
            slist => slist.map(s => s));

        const ownerMap = new Map(Object.entries(ownerGroup));

        ownerMap.forEach((markerObjArr, owner, map) => {

            if (markerObjArr === null || markerObjArr === undefined || markerObjArr.length === 0) {
                // do nothing
            }
            else 
            {
                let ownerName = owner;
                if (ownerName === "undefined") {
                    ownerName = "No Owner";
                }

                // if (this.metricsByOwner === null || this.metricsByOwner === undefined) {
                //     this.metricsByOwner = new Map<string, SensorMetrics[]>();
                // }

                let ownerMetrics = this.metricsByOwner.get(ownerName);
                if (ownerMetrics === undefined) {
                    ownerMetrics = new Array<SensorMetrics>();
                    //Add a new entry for this owner in the Map
                    this.metricsByOwner.set(ownerName, ownerMetrics);
                }

                let rssiSensorMetric = this.getSensorMetric(SensorMetricType.RSSI, markerObjArr);
                if(rssiSensorMetric !== null)
                {
                    ownerMetrics.push(rssiSensorMetric);
                }

                let snrSensorMetric = this.getSensorMetric(SensorMetricType.SNR, markerObjArr);
                if(snrSensorMetric !== null)
                {
                    ownerMetrics.push(snrSensorMetric);
                }
            }

        });

    }

    private getSensorMetric(metricTyp: SensorMetricType, markerModelArr: IMarkerModel[]) {
        let pcntGood: number = 0;
        let pcntOk: number = 0;
        let pcntWeak: number = 0;
        let pcntActive: number = 0;
        let pcntInactive: number = 0;
        let sensorCountForCurrentOwner: number = 0;
        if (markerModelArr !== undefined) {
            sensorCountForCurrentOwner = markerModelArr.length;
        }

        if (metricTyp === SensorMetricType.RSSI) {
            let rssiCondition = _.mapValues(_.groupBy(markerModelArr, 'rssiMetricCondition'),
                slist => slist.map(s => _.omit(s, 'rssiMetricCondition')));

            const rssiConditionMap = new Map(Object.entries(rssiCondition));
            rssiConditionMap.forEach((objArr, rssiMetricCondition, rssiMap) => {
                if (rssiMetricCondition === SensorMetricCondition.Hr24SinceReported.valueOf().toString()) {
                    pcntInactive = _.round((objArr.length / sensorCountForCurrentOwner * 100));
                }
                else if (rssiMetricCondition === SensorMetricCondition.Good.valueOf().toString()) {
                    pcntGood = _.round((objArr.length / sensorCountForCurrentOwner * 100));
                }
                else if (rssiMetricCondition === SensorMetricCondition.Okay.valueOf().toString()) {
                    pcntOk = _.round((objArr.length / sensorCountForCurrentOwner * 100));
                }
                else if (rssiMetricCondition === SensorMetricCondition.Weak.valueOf().toString()) {
                    pcntWeak = _.round((objArr.length / sensorCountForCurrentOwner * 100));
                }
            });

            let rssiActive = _.mapValues(_.groupBy(markerModelArr, 'isActive'),
                slist => slist.map(s => _.omit(s, 'isActive')));

            const rssiActiveMap = new Map(Object.entries(rssiActive));
            rssiActiveMap.forEach((arr, active, activeM) => {
                if (active === "true") {
                    pcntActive = _.round((arr.length / sensorCountForCurrentOwner * 100));
                }
            });

            return new SensorMetrics(SensorMetricType.RSSI, pcntGood, pcntOk, pcntWeak, pcntActive, pcntInactive, sensorCountForCurrentOwner);

        }
        else if (metricTyp === SensorMetricType.SNR) {
            let snrCondition = _.mapValues(_.groupBy(markerModelArr, 'snrMetricCondition'),
                slist => slist.map(s => _.omit(s, 'snrMetricCondition')));

            const snrConditionMap = new Map(Object.entries(snrCondition));
            snrConditionMap.forEach((objArr, snrMetricCondition, snrMap) => {
                if (snrMetricCondition === SensorMetricCondition.Hr24SinceReported.valueOf().toString()) {
                    pcntInactive = _.round((objArr.length / sensorCountForCurrentOwner * 100));
                }
                else if (snrMetricCondition === SensorMetricCondition.Good.valueOf().toString()) {
                    pcntGood = _.round((objArr.length / sensorCountForCurrentOwner * 100));
                }
                else if (snrMetricCondition === SensorMetricCondition.Okay.valueOf().toString()) {
                    pcntOk = _.round((objArr.length / sensorCountForCurrentOwner * 100));
                }
                else if (snrMetricCondition === SensorMetricCondition.Weak.valueOf().toString()) {
                    pcntWeak = _.round((objArr.length / sensorCountForCurrentOwner * 100));
                }
            });

            let snrActive = _.mapValues(_.groupBy(markerModelArr, 'isActive'),
                slist => slist.map(s => _.omit(s, 'isActive')));

            const snrActiveMap = new Map(Object.entries(snrActive));
            snrActiveMap.forEach((arr, active, activeM) => {
                if (active === "true") {
                    pcntActive = _.round((arr.length / sensorCountForCurrentOwner * 100));
                }
            });

            return new SensorMetrics(SensorMetricType.SNR, pcntGood, pcntOk, pcntWeak, pcntActive, pcntInactive, sensorCountForCurrentOwner);

        }
        return null;
    }
}