import { Route, Routes } from "react-router";
import DashboardPage from "../pages/dashboardPage/DashboardPage";
import LoginPage from "../pages/loginPage/LoginPage";
import MapPage from "../pages/mapPage/MapPage";
import NotFoundPage from "../pages/misc/NotFoundPage";
import UnauthenticatedPage from "../pages/misc/UnauthenticatedPage";
import ReactLayout from "./ReactLayout";
import RequireAuth from "./RequireAuth";

const RoutesProvider = () => (
  <Routes>
    <Route path="/" element={<ReactLayout />}>
      {/* public routes */}
      <Route path="/login" element={<LoginPage />} />
      {/* Authenticated Routes */}
      <Route element={<RequireAuth />}>
        <Route path="/:orgId/:projectId/map" element={<MapPage />} />
      </Route>
      <Route element={<RequireAuth />}>
        <Route path="/:orgId/:projectId/" element={<DashboardPage />} />
      </Route>
      {/* NotFound */}
      <Route path="/" element={<NotFoundPage />} />
      <Route path="*" element={<NotFoundPage />} />
      <Route path="/401" element={<UnauthenticatedPage />} />
    </Route>
  </Routes>
);

export default RoutesProvider;
