import { useState } from "react";
import {
  Dropdown,
  DropdownItem,
  MultiSelectBox,
  MultiSelectBoxItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
  Text,
  Toggle,
  ToggleItem
} from "@tremor/react";
import { IMarkerModel, SensorMetricCondition } from "../../../models/CoreTypes";
import _ from "lodash";

export default function SensorDetails(props: any) {
  const [selectedRSSIStatus, setSelectedRSSIStatus] = useState("all");
  const [selectedSNRStatus, setSelectedSNRStatus] = useState("all");
  const [selectedNames, setSelectedNames] = useState<string[]>([]);
  const [inActiveStatus, setInactiveStatus] = useState("1");

  function isCriteriaSelected(marker: IMarkerModel) {
    let rssiCriteriaPass: boolean = false;
    let snrCriteriaPass: boolean = false;
    let ownerCriteriaPass: boolean = false;
    let inactiveCriteriaPass : boolean = false;

    if (selectedRSSIStatus === "all") {
      rssiCriteriaPass = true;
    }
    else if (marker.rssiMetricCondition === undefined) {
      rssiCriteriaPass = false;
    }
    else {
      rssiCriteriaPass = SensorMetricCondition[marker.rssiMetricCondition] === SensorMetricCondition[_.toNumber(selectedRSSIStatus)];
    }

    if (selectedSNRStatus === "all") {
      snrCriteriaPass = true;
    }
    else if (marker.snrMetricCondition === undefined) {
      snrCriteriaPass = false;
    }
    else {
      snrCriteriaPass = SensorMetricCondition[marker.snrMetricCondition] === SensorMetricCondition[_.toNumber(selectedSNRStatus)];
    }

    ownerCriteriaPass = selectedNames.includes(marker.owner) || selectedNames.length === 0;

    inactiveCriteriaPass = (inActiveStatus === "1" ? true : (marker.isActive === false)); //If "Only Inactive" Criteria is selected then check marker is Inactive else pass through

    if (rssiCriteriaPass && snrCriteriaPass && ownerCriteriaPass && inactiveCriteriaPass) {
      return true;
    }
    else {
      return false;
    }

  }

  function getMarkersByOwner() {
    //Group sensors by owner(s)
    let ownerGroup = _.mapValues(_.groupBy(props.markerModels, 'owner'),
      slist => slist.map(s => s));

    const ownerMap = new Map(Object.entries(ownerGroup));

    return Array.from(ownerMap.keys());
  }


  return (
    <>
      <div className="sm:mt-6 hidden sm:flex sm:start sm:space-x-2 items-center">
        <MultiSelectBox
          onValueChange={(value) => setSelectedNames(value)}
          placeholder="Select Customer"
          className="max-w-xs"
        >
          {getMarkersByOwner().map((item: string) => (
            <MultiSelectBoxItem
              key={item}
              value={item}
              text={item}
            />
          ))}
        </MultiSelectBox>
        <Text>RSSI Strengths</Text>
        <Dropdown
          className="max-w-xs"
          defaultValue="all"
          onValueChange={(value) => setSelectedRSSIStatus(value)}
        >
          <DropdownItem value="all" text="All Strengths" />
          <DropdownItem value={SensorMetricCondition.Good.toString()} text="Strong" />
          <DropdownItem value={SensorMetricCondition.Okay.toString()} text="Okay" />
          <DropdownItem value={SensorMetricCondition.Weak.toString()} text="Weak" />
        </Dropdown>
        <Text>SNR Strengths</Text>
        <Dropdown
          className="max-w-xs"
          defaultValue="all"
          onValueChange={(value) => setSelectedSNRStatus(value)}
        >
          <DropdownItem value="all" text="All Strengths" />
          <DropdownItem value={SensorMetricCondition.Good.toString()} text="Strong" />
          <DropdownItem value={SensorMetricCondition.Okay.toString()} text="Okay" />
          <DropdownItem value={SensorMetricCondition.Weak.toString()} text="Weak" />
        </Dropdown>
        <Toggle
          defaultValue="1"
          onValueChange={(value) => setInactiveStatus(value)}
        >
          <ToggleItem value="1" text="Show All" />
          <ToggleItem value="2" text="Show Inactive(s)" />
        </Toggle>
      </div>
      <div className="mt-6 sm:hidden space-y-2 sm:space-y-0 items-center">
        <MultiSelectBox
          onValueChange={(value) => setSelectedNames(value)}
          placeholder="Select Customer"
          className="max-w-full"
        >
          {getMarkersByOwner().map((item: string) => (
            <MultiSelectBoxItem
              key={item}
              value={item}
              text={item}
            />
          ))}
        </MultiSelectBox>
        <Text >RSSI Strengths</Text>
        <Dropdown
          className="max-w-full"
          defaultValue="all"
          onValueChange={(value) => setSelectedRSSIStatus(value)}
        >
          <DropdownItem value="all" text="All Strengths" />
          <DropdownItem value={SensorMetricCondition.Good.toString()} text="Strong" />
          <DropdownItem value={SensorMetricCondition.Okay.toString()} text="Okay" />
          <DropdownItem value={SensorMetricCondition.Weak.toString()} text="Weak" />
        </Dropdown>
        <Text>SNR Strengths</Text>
        <Dropdown
          className="max-w-full"
          defaultValue="all"
          onValueChange={(value) => setSelectedSNRStatus(value)}
        >
          <DropdownItem value="all" text="All Strengths" />
          <DropdownItem value={SensorMetricCondition.Good.toString()} text="Strong" />
          <DropdownItem value={SensorMetricCondition.Okay.toString()} text="Okay" />
          <DropdownItem value={SensorMetricCondition.Weak.toString()} text="Weak" />
        </Dropdown>
        <Toggle
          defaultValue="1"
          onValueChange={(value) => setInactiveStatus(value)}
        >
          <ToggleItem value="1" text="Show All" />
          <ToggleItem value="2" text="Show Inactive(s)" />
        </Toggle>
      </div>

      <Table className="mt-6">
        <TableHead>
          <TableRow>
            <TableHeaderCell>Device Name</TableHeaderCell>
            <TableHeaderCell>Owner</TableHeaderCell>
            <TableHeaderCell className="text-right">RSSI</TableHeaderCell>
            <TableHeaderCell className="text-right">SNR</TableHeaderCell>
            <TableHeaderCell className="text-right">Spreading Factor</TableHeaderCell>
            <TableHeaderCell>Inactive? (Yes/No)</TableHeaderCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {props.markerModels
            .filter((item: IMarkerModel) => isCriteriaSelected(item))
            .map((item: IMarkerModel) => (
              <TableRow key={item.name}>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.owner}</TableCell>
                <TableCell style={{ backgroundColor: item.rssiMetricColor, color: "white", fontWeight: "bolder" }} className="text-right">{item.rssi}</TableCell>
                <TableCell style={{ backgroundColor: item.snrMetricColor, color: "white", fontWeight: "bolder" }} className="text-right">{item.snr}</TableCell>
                <TableCell className="text-right">{item.spreadingFactor}</TableCell>
                <TableCell>{!item.isActive ? "Yes" : "No"}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </>
  );
}