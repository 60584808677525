/* eslint-disable no-lonely-if */
import { useQuery } from "@tanstack/react-query";
import { Auth } from "aws-amplify";
import { isEmpty } from "lodash";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { OrganizationAPI } from "../services/apis/organizationAPI";
import { ProjectAPI } from "../services/apis/projectAPI";
import { RolesAPI } from "../services/apis/rolesAPI";
import { ServiceAPI } from "../services/apis/serviceAPI";
import { USER_ROLES } from "../utils/helpers";
import { IContextData, IContextUser } from "../utils/interface";

const initialUserData: IContextUser = {
  email: "",
  userId: "",
  username: "",
  userRole: "",
};

export const AuthContext = createContext<IContextData>({
  email: "",
  userId: "",
  username: "",
  userRole: "",
  isLoadingUser: false,
  logout: () => {},
  setUser: () => {},
});

const AuthContextProvider = ({ children }: any) => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState<IContextUser>(initialUserData);
  const [loading, setLoading] = useState<boolean>(true);
  const [orgId, setOrgId] = useState<string>("");
  const [userRole, setUserRole] = useState<string>("");
  const [projectId, setProjectId] = useState<string>("");
  const [serviceId, setServiceId] = useState<string>("");
  const { pathname } = useLocation();
  const orgName = pathname.split("/")[1];
  const projectName = pathname.split("/")[2];
  const serviceName = "Signal Strength";

  if (!projectName && !orgName) {
    navigate("/404");
  }

  useQuery(
    ["getServiceId"],
    () => ServiceAPI.getServiceByServiceName(serviceName),
    {
      enabled: !!serviceName,
      onSuccess: (res: any) => {
        setServiceId(res.data.listDimDocks30s.items[0].ServiceId);
      },
    }
  );

  useQuery(
    ["getOrganization", orgName],
    () => OrganizationAPI.getOrganizationByName(orgName),
    {
      enabled: !isEmpty(orgName),
      onSuccess: (res: any) => {
        setOrgId(res.data.listDimDocks30s.items[0]?.OrganizationId);
      },
    }
  );

  useQuery(
    ["getProject", orgId],
    () => ProjectAPI.getProjectByName(projectName, orgId, userInfo.userId),
    {
      enabled: !!orgId,
      onSuccess: (res: any) => {
        setProjectId(res.data.listDimDocks30s.items[0]?.ProjectId);
      },
    }
  );

  useQuery(
    ["isOrgAdmin", orgId, userInfo.userId, userRole],
    () => OrganizationAPI.getUserAdminAPI(orgId, userInfo.userId),
    {
      enabled:
        !!userInfo.userId && !!orgId && userRole !== USER_ROLES.superAdmin,
      onSuccess: (res: any) => {
        if (res) {
          setUserRole(USER_ROLES.orgAdmin);
          setLoading(false);
          navigate(`/${orgName}/${projectName}/map`);
        }
      },
    }
  );

  useQuery(
    ["getServiceRole", userInfo.userId, orgId, projectId, serviceId, userRole],
    () =>
      RolesAPI.getUserServiceRoleAPI(
        userInfo.userId,
        orgId,
        projectId,
        serviceId
      ),
    {
      enabled:
        !!projectId &&
        !!orgId &&
        !!serviceId &&
        userRole !== USER_ROLES.orgAdmin &&
        userRole !== USER_ROLES.superAdmin,
      onSuccess: (res: any) => {
        const roleId = res.data.listDimDocks30s.items[0]?.RoleId;
        RolesAPI.getUserRole(roleId).then((res: any) => {
          const roleName = res.data.listDimDocks30s.items[0]?.RoleName;
          setUserRole(roleName);
          setLoading(false);
          navigate(`/${orgName}/${projectName}/map`);
        });
      },
    }
  );

  const getUserRole = () =>
    // eslint-disable-next-line no-nested-ternary
    userRole;

  useEffect(() => {
    setUser({
      ...userInfo,
      userRole: userRole,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRole]);

  const setUser = useCallback(
    (data: any) => {
      // Update the user state
      setUserInfo({
        ...userInfo,
        ...data,
      });
    },
    [userInfo]
  );

  const logout = useCallback(() => {
    Auth.signOut().then(() => {
      setUserInfo(initialUserData);
      setUserRole("")
      navigate("/login");
    });
  }, [navigate]);

  useEffect(() => {
    setLoading(true);
    Auth.currentAuthenticatedUser()
      .then((user: any) => {
        if (user?.attributes["custom:role"]) {
          setUserRole(USER_ROLES.superAdmin);
          setLoading(false);
          setUserInfo({
            userId: user.attributes.sub,
            email: user.username,
            username: user.username,
            userRole: USER_ROLES.superAdmin,
          });
          navigate(`/${orgName}/${projectName}/map`);
        } else {
          setUserInfo({
            userId: user.attributes.sub,
            email: user.username,
            username: user.username,
            userRole: getUserRole(),
          });
        }
      })
      .catch(() => logout());
    // .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const authContextValue: IContextData = useMemo(
    () => ({
      logout,
      setUser,
      userRole: userRole,
      username: userInfo?.username,
      userId: userInfo?.userId,
      email: userInfo?.email,
      isLoadingUser: loading,
    }),
    [
      logout,
      setUser,
      userRole,
      userInfo?.username,
      userInfo?.userId,
      userInfo?.email,
      loading,
    ]
  );

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export default AuthContextProvider;
