import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router";
import { useAuth } from "../../context/AuthContext";
import { components, formFields } from "../../utils/aws";
import { IContextData } from "../../utils/interface";
import { LoginStyles } from "./LoginPage.css";

const LoginPage = () => {
  const navigate = useNavigate();
  const { setUser }: IContextData = useAuth();

  const completeLogin = async (userInfo: any) => {
    const { sub, email } = userInfo.attributes;
    const userData = {
      userId: sub,
      email,
      username: userInfo.username,
    };
    if (setUser) setUser(userData);
    navigate("/404");
    return;
  };

  const services = {
    async handleSignIn(formData: any) {
      const username = formData.username.toLowerCase();
      const password = formData.password;

      try {
        const userInfo: any = await Auth.signIn({ username, password });
        if (!userInfo.challengeName) {
          completeLogin(userInfo);
        }
        return userInfo;
      } catch (error: any) {
        throw new Error(error.message);
      }
    },
    async handleConfirmSignIn({
      user,
      code,
      mfaType,
    }: {
      user: any;
      code: string;
      mfaType: any;
    }) {
      try {
        const userInfo = await Auth.confirmSignIn(user, code, mfaType);
        completeLogin(userInfo);
        return userInfo;
      } catch (error: any) {
        throw new Error(error.message);
      }
    },
  };

  return (
    <LoginStyles>
      <Authenticator
        services={services}
        formFields={formFields}
        components={components}
        hideSignUp
        className="authenticator-container"
      />
    </LoginStyles>
  );
};

export default LoginPage;
