import { API, graphqlOperation } from "aws-amplify";
import { getUserRole } from "../../graphql/customQueries";
import { listDimDocks30s } from "../../graphql/queries";

export const RolesAPI = {
  async getUserRole(roleId: string) {
    const queryParams = {
      filter: {
        pk: {
          eq: "ROLE",
        },
        sk: {
          eq: `ROLE#${roleId}`,
        },
        IsDeleted: {
          ne: true,
        },
      },
      limit: 5000000,
    };
    return new Promise(async (resolve, reject) => {
      try {
        const result = await API.graphql(
          graphqlOperation(getUserRole, queryParams)
        );
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  },
  async getUserServiceRoleAPI(
  userId: string,
  organizationId: string,
  projectId: string,
  serviceId : string
) {
  const queryVariables = {
    limit: 5000000,
    filter: {
      pk: {
        eq: `ORGANIZATION#${organizationId}`,
      },
      sk: {
        contains: `PROJECT#${projectId}SERVICE#${serviceId}USER#${userId}`,
      },
    },
  };
  return new Promise(async (resolve, reject) => {
    try {
      const result = await API.graphql(
        graphqlOperation(listDimDocks30s, queryVariables)
      );
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
}
};
