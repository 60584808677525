export const USER_ROLES = {
  superAdmin: "SuperAdmin",
  orgAdmin: "Organization Admin",
  projectAdmin: "Project Admin",
  writer: "Writer",
  reader: "Reader",
  user: "User",
};

export const PlatformRolesList = [
  "SuperAdmin",
  "Organization Admin",
  "Project Admin",
  "Reader",
  "Writer",
  "User",
];

export const getGlobalRoleColor = (role: string) => {
  switch (role) {
    case "Super Admin":
      return "#dc3545";
    case "Organization Admin":
      return "#198754";
    case "Project Admin":
      return "#0d6efd";
    case "Writer":
      return "#13c413";
    default:
      return "#fd7e14";
  }
};

export const getPlatformRole = (input: String | undefined) => {
  switch (input) {
    case PlatformRolesList[0]:
      return PlatformRolesList[0];
    case PlatformRolesList[1]:
      return PlatformRolesList[1];
    case PlatformRolesList[2]:
      return PlatformRolesList[2];
    case PlatformRolesList[3]:
      return PlatformRolesList[3];
    case PlatformRolesList[4]:
      return PlatformRolesList[4];
    case PlatformRolesList[5]:
      return PlatformRolesList[5];
    default:
      return "";
  }
};
