let dummyDevices = [
    {
        "id": 1, "latlng": [59.296573982403544, 18.080922364977685], "name": "WSP Huvudentré", "reportedAt": "2023-02-27T09:35:22.104Z",
        "owner": "WSP", "rssi": -120, "snr": -3, "spreadingFactor": 7,
        "timeSeries": [{ 'metric': 'rssi', 'value': -120, 'mean': -120, 'time': '2023-03-03T18:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-04T00:00:00Z' },
        { 'metric': 'rssi', 'value': -117.75, 'mean': -117.75, 'time': '2023-03-04T06:00:00Z' },
        { 'metric': 'rssi', 'value': -116, 'mean': -116, 'time': '2023-03-04T12:00:00Z' },
        { 'metric': 'rssi', 'value': -117, 'mean': -117, 'time': '2023-03-04T18:00:00Z' },
        { 'metric': 'rssi', 'value': -116.75, 'mean': -116.75, 'time': '2023-03-05T00:00:00Z' },
        { 'metric': 'rssi', 'value': -122, 'mean': -122, 'time': '2023-03-05T06:00:00Z' },
        { 'metric': 'rssi', 'value': -113, 'mean': -113, 'time': '2023-03-05T12:00:00Z' },
        { 'metric': 'rssi', 'value': -116.5, 'mean': -116.5, 'time': '2023-03-05T18:00:00Z' },
        { 'metric': 'rssi', 'value': -115.5, 'mean': -115.5, 'time': '2023-03-06T00:00:00Z' },
        { 'metric': 'rssi', 'value': -114.66666666666667, 'mean': -114.66666666666667, 'time': '2023-03-06T06:00:00Z' },
        { 'metric': 'rssi', 'value': -114.8, 'mean': -114.8, 'time': '2023-03-06T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-06T18:00:00Z' },
        { 'metric': 'rssi', 'value': -117.4, 'mean': -117.4, 'time': '2023-03-07T00:00:00Z' },
        { 'metric': 'rssi', 'value': -117.33333333333333, 'mean': -117.33333333333333, 'time': '2023-03-07T06:00:00Z' },
        { 'metric': 'rssi', 'value': -115.66666666666667, 'mean': -115.66666666666667, 'time': '2023-03-07T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-08T00:00:00Z' },
        { 'metric': 'rssi', 'value': -115, 'mean': -115, 'time': '2023-03-08T06:00:00Z' },
        { 'metric': 'rssi', 'value': -116.66666666666667, 'mean': -116.66666666666667, 'time': '2023-03-08T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119.5, 'mean': -119.5, 'time': '2023-03-08T18:00:00Z' },
        { 'metric': 'rssi', 'value': -115.66666666666667, 'mean': -115.66666666666667, 'time': '2023-03-09T00:00:00Z' },
        { 'metric': 'rssi', 'value': -117, 'mean': -117, 'time': '2023-03-09T06:00:00Z' },
        { 'metric': 'rssi', 'value': -118.33333333333333, 'mean': -118.33333333333333, 'time': '2023-03-09T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-09T18:00:00Z' },
        { 'metric': 'rssi', 'value': -119.75, 'mean': -119.75, 'time': '2023-03-10T00:00:00Z' },
        { 'metric': 'rssi', 'value': -119.33333333333333, 'mean': -119.33333333333333, 'time': '2023-03-10T06:00:00Z' },
        { 'metric': 'rssi', 'value': -117.25, 'mean': -117.25, 'time': '2023-03-10T12:00:00Z' },
        { 'metric': 'rssi', 'value': -114, 'mean': -114, 'time': '2023-03-10T12:51:00.529Z' }]
    },
    {
        "id": 2, "latlng": [59.29672156366285, 18.080925472921901], "name": "WSP ", "reportedAt": "2023-02-27T09:35:22.104Z", "owner": "WSP", "rssi": -107, "snr": -3, "spreadingFactor": 7,
        "timeSeries": [{ 'metric': 'rssi', 'value': -120, 'mean': -120, 'time': '2023-03-03T18:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-04T00:00:00Z' },
        { 'metric': 'rssi', 'value': -117.75, 'mean': -117.75, 'time': '2023-03-04T06:00:00Z' },
        { 'metric': 'rssi', 'value': -116, 'mean': -116, 'time': '2023-03-04T12:00:00Z' },
        { 'metric': 'rssi', 'value': -117, 'mean': -117, 'time': '2023-03-04T18:00:00Z' },
        { 'metric': 'rssi', 'value': -116.75, 'mean': -116.75, 'time': '2023-03-05T00:00:00Z' },
        { 'metric': 'rssi', 'value': -122, 'mean': -122, 'time': '2023-03-05T06:00:00Z' },
        { 'metric': 'rssi', 'value': -113, 'mean': -113, 'time': '2023-03-05T12:00:00Z' },
        { 'metric': 'rssi', 'value': -116.5, 'mean': -116.5, 'time': '2023-03-05T18:00:00Z' },
        { 'metric': 'rssi', 'value': -115.5, 'mean': -115.5, 'time': '2023-03-06T00:00:00Z' },
        { 'metric': 'rssi', 'value': -114.66666666666667, 'mean': -114.66666666666667, 'time': '2023-03-06T06:00:00Z' },
        { 'metric': 'rssi', 'value': -114.8, 'mean': -114.8, 'time': '2023-03-06T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-06T18:00:00Z' },
        { 'metric': 'rssi', 'value': -117.4, 'mean': -117.4, 'time': '2023-03-07T00:00:00Z' },
        { 'metric': 'rssi', 'value': -117.33333333333333, 'mean': -117.33333333333333, 'time': '2023-03-07T06:00:00Z' },
        { 'metric': 'rssi', 'value': -115.66666666666667, 'mean': -115.66666666666667, 'time': '2023-03-07T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-08T00:00:00Z' },
        { 'metric': 'rssi', 'value': -115, 'mean': -115, 'time': '2023-03-08T06:00:00Z' },
        { 'metric': 'rssi', 'value': -116.66666666666667, 'mean': -116.66666666666667, 'time': '2023-03-08T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119.5, 'mean': -119.5, 'time': '2023-03-08T18:00:00Z' },
        { 'metric': 'rssi', 'value': -115.66666666666667, 'mean': -115.66666666666667, 'time': '2023-03-09T00:00:00Z' },
        { 'metric': 'rssi', 'value': -117, 'mean': -117, 'time': '2023-03-09T06:00:00Z' },
        { 'metric': 'rssi', 'value': -118.33333333333333, 'mean': -118.33333333333333, 'time': '2023-03-09T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-09T18:00:00Z' },
        { 'metric': 'rssi', 'value': -119.75, 'mean': -119.75, 'time': '2023-03-10T00:00:00Z' },
        { 'metric': 'rssi', 'value': -119.33333333333333, 'mean': -119.33333333333333, 'time': '2023-03-10T06:00:00Z' },
        { 'metric': 'rssi', 'value': -117.25, 'mean': -117.25, 'time': '2023-03-10T12:00:00Z' },
        { 'metric': 'rssi', 'value': -114, 'mean': -114, 'time': '2023-03-10T12:51:00.529Z' }]
    },
    {
        "id": 3, "latlng": [59.296724737446347, 18.081385448666392], "name": "WSP vån 7", "reportedAt": "2023-02-27T09:35:22.104Z", "owner": "WSP", "rssi": -110, "snr": 4, "spreadingFactor": 11,
        "timeSeries": [{ 'metric': 'rssi', 'value': -120, 'mean': -120, 'time': '2023-03-03T18:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-04T00:00:00Z' },
        { 'metric': 'rssi', 'value': -117.75, 'mean': -117.75, 'time': '2023-03-04T06:00:00Z' },
        { 'metric': 'rssi', 'value': -116, 'mean': -116, 'time': '2023-03-04T12:00:00Z' },
        { 'metric': 'rssi', 'value': -117, 'mean': -117, 'time': '2023-03-04T18:00:00Z' },
        { 'metric': 'rssi', 'value': -116.75, 'mean': -116.75, 'time': '2023-03-05T00:00:00Z' },
        { 'metric': 'rssi', 'value': -122, 'mean': -122, 'time': '2023-03-05T06:00:00Z' },
        { 'metric': 'rssi', 'value': -113, 'mean': -113, 'time': '2023-03-05T12:00:00Z' },
        { 'metric': 'rssi', 'value': -116.5, 'mean': -116.5, 'time': '2023-03-05T18:00:00Z' },
        { 'metric': 'rssi', 'value': -115.5, 'mean': -115.5, 'time': '2023-03-06T00:00:00Z' },
        { 'metric': 'rssi', 'value': -114.66666666666667, 'mean': -114.66666666666667, 'time': '2023-03-06T06:00:00Z' },
        { 'metric': 'rssi', 'value': -114.8, 'mean': -114.8, 'time': '2023-03-06T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-06T18:00:00Z' },
        { 'metric': 'rssi', 'value': -117.4, 'mean': -117.4, 'time': '2023-03-07T00:00:00Z' },
        { 'metric': 'rssi', 'value': -117.33333333333333, 'mean': -117.33333333333333, 'time': '2023-03-07T06:00:00Z' },
        { 'metric': 'rssi', 'value': -115.66666666666667, 'mean': -115.66666666666667, 'time': '2023-03-07T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-08T00:00:00Z' },
        { 'metric': 'rssi', 'value': -115, 'mean': -115, 'time': '2023-03-08T06:00:00Z' },
        { 'metric': 'rssi', 'value': -116.66666666666667, 'mean': -116.66666666666667, 'time': '2023-03-08T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119.5, 'mean': -119.5, 'time': '2023-03-08T18:00:00Z' },
        { 'metric': 'rssi', 'value': -115.66666666666667, 'mean': -115.66666666666667, 'time': '2023-03-09T00:00:00Z' },
        { 'metric': 'rssi', 'value': -117, 'mean': -117, 'time': '2023-03-09T06:00:00Z' },
        { 'metric': 'rssi', 'value': -118.33333333333333, 'mean': -118.33333333333333, 'time': '2023-03-09T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-09T18:00:00Z' },
        { 'metric': 'rssi', 'value': -119.75, 'mean': -119.75, 'time': '2023-03-10T00:00:00Z' },
        { 'metric': 'rssi', 'value': -119.33333333333333, 'mean': -119.33333333333333, 'time': '2023-03-10T06:00:00Z' },
        { 'metric': 'rssi', 'value': -117.25, 'mean': -117.25, 'time': '2023-03-10T12:00:00Z' },
        { 'metric': 'rssi', 'value': -114, 'mean': -114, 'time': '2023-03-10T12:51:00.529Z' }]
    },
    {
        "id": 4, "latlng": [59.296578743099317, 18.081382340722172], "name": "WSP Vån 6 Sektion B", "reportedAt": "2023-02-27T09:35:22.104Z", "owner": "WSP", "rssi": -120, "snr": 5, "spreadingFactor": 7,
        "timeSeries": [{ 'metric': 'rssi', 'value': -120, 'mean': -120, 'time': '2023-03-03T18:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-04T00:00:00Z' },
        { 'metric': 'rssi', 'value': -117.75, 'mean': -117.75, 'time': '2023-03-04T06:00:00Z' },
        { 'metric': 'rssi', 'value': -116, 'mean': -116, 'time': '2023-03-04T12:00:00Z' },
        { 'metric': 'rssi', 'value': -117, 'mean': -117, 'time': '2023-03-04T18:00:00Z' },
        { 'metric': 'rssi', 'value': -116.75, 'mean': -116.75, 'time': '2023-03-05T00:00:00Z' },
        { 'metric': 'rssi', 'value': -122, 'mean': -122, 'time': '2023-03-05T06:00:00Z' },
        { 'metric': 'rssi', 'value': -113, 'mean': -113, 'time': '2023-03-05T12:00:00Z' },
        { 'metric': 'rssi', 'value': -116.5, 'mean': -116.5, 'time': '2023-03-05T18:00:00Z' },
        { 'metric': 'rssi', 'value': -115.5, 'mean': -115.5, 'time': '2023-03-06T00:00:00Z' },
        { 'metric': 'rssi', 'value': -114.66666666666667, 'mean': -114.66666666666667, 'time': '2023-03-06T06:00:00Z' },
        { 'metric': 'rssi', 'value': -114.8, 'mean': -114.8, 'time': '2023-03-06T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-06T18:00:00Z' },
        { 'metric': 'rssi', 'value': -117.4, 'mean': -117.4, 'time': '2023-03-07T00:00:00Z' },
        { 'metric': 'rssi', 'value': -117.33333333333333, 'mean': -117.33333333333333, 'time': '2023-03-07T06:00:00Z' },
        { 'metric': 'rssi', 'value': -115.66666666666667, 'mean': -115.66666666666667, 'time': '2023-03-07T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-08T00:00:00Z' },
        { 'metric': 'rssi', 'value': -115, 'mean': -115, 'time': '2023-03-08T06:00:00Z' },
        { 'metric': 'rssi', 'value': -116.66666666666667, 'mean': -116.66666666666667, 'time': '2023-03-08T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119.5, 'mean': -119.5, 'time': '2023-03-08T18:00:00Z' },
        { 'metric': 'rssi', 'value': -115.66666666666667, 'mean': -115.66666666666667, 'time': '2023-03-09T00:00:00Z' },
        { 'metric': 'rssi', 'value': -117, 'mean': -117, 'time': '2023-03-09T06:00:00Z' },
        { 'metric': 'rssi', 'value': -118.33333333333333, 'mean': -118.33333333333333, 'time': '2023-03-09T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-09T18:00:00Z' },
        { 'metric': 'rssi', 'value': -119.75, 'mean': -119.75, 'time': '2023-03-10T00:00:00Z' },
        { 'metric': 'rssi', 'value': -119.33333333333333, 'mean': -119.33333333333333, 'time': '2023-03-10T06:00:00Z' },
        { 'metric': 'rssi', 'value': -117.25, 'mean': -117.25, 'time': '2023-03-10T12:00:00Z' },
        { 'metric': 'rssi', 'value': -114, 'mean': -114, 'time': '2023-03-10T12:51:00.529Z' }]
    },
    {
        "id": 5, "latlng": [59.296724737446347, 18.081568817375345], "name": "WSP 3", "reportedAt": "2023-02-27T09:35:22.104Z", "owner": "WSP", "rssi": -109, "snr": -13, "spreadingFactor": 11,
        "timeSeries": [{ 'metric': 'rssi', 'value': -120, 'mean': -120, 'time': '2023-03-03T18:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-04T00:00:00Z' },
        { 'metric': 'rssi', 'value': -117.75, 'mean': -117.75, 'time': '2023-03-04T06:00:00Z' },
        { 'metric': 'rssi', 'value': -116, 'mean': -116, 'time': '2023-03-04T12:00:00Z' },
        { 'metric': 'rssi', 'value': -117, 'mean': -117, 'time': '2023-03-04T18:00:00Z' },
        { 'metric': 'rssi', 'value': -116.75, 'mean': -116.75, 'time': '2023-03-05T00:00:00Z' },
        { 'metric': 'rssi', 'value': -122, 'mean': -122, 'time': '2023-03-05T06:00:00Z' },
        { 'metric': 'rssi', 'value': -113, 'mean': -113, 'time': '2023-03-05T12:00:00Z' },
        { 'metric': 'rssi', 'value': -116.5, 'mean': -116.5, 'time': '2023-03-05T18:00:00Z' },
        { 'metric': 'rssi', 'value': -115.5, 'mean': -115.5, 'time': '2023-03-06T00:00:00Z' },
        { 'metric': 'rssi', 'value': -114.66666666666667, 'mean': -114.66666666666667, 'time': '2023-03-06T06:00:00Z' },
        { 'metric': 'rssi', 'value': -114.8, 'mean': -114.8, 'time': '2023-03-06T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-06T18:00:00Z' },
        { 'metric': 'rssi', 'value': -117.4, 'mean': -117.4, 'time': '2023-03-07T00:00:00Z' },
        { 'metric': 'rssi', 'value': -117.33333333333333, 'mean': -117.33333333333333, 'time': '2023-03-07T06:00:00Z' },
        { 'metric': 'rssi', 'value': -115.66666666666667, 'mean': -115.66666666666667, 'time': '2023-03-07T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-08T00:00:00Z' },
        { 'metric': 'rssi', 'value': -115, 'mean': -115, 'time': '2023-03-08T06:00:00Z' },
        { 'metric': 'rssi', 'value': -116.66666666666667, 'mean': -116.66666666666667, 'time': '2023-03-08T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119.5, 'mean': -119.5, 'time': '2023-03-08T18:00:00Z' },
        { 'metric': 'rssi', 'value': -115.66666666666667, 'mean': -115.66666666666667, 'time': '2023-03-09T00:00:00Z' },
        { 'metric': 'rssi', 'value': -117, 'mean': -117, 'time': '2023-03-09T06:00:00Z' },
        { 'metric': 'rssi', 'value': -118.33333333333333, 'mean': -118.33333333333333, 'time': '2023-03-09T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-09T18:00:00Z' },
        { 'metric': 'rssi', 'value': -119.75, 'mean': -119.75, 'time': '2023-03-10T00:00:00Z' },
        { 'metric': 'rssi', 'value': -119.33333333333333, 'mean': -119.33333333333333, 'time': '2023-03-10T06:00:00Z' },
        { 'metric': 'rssi', 'value': -117.25, 'mean': -117.25, 'time': '2023-03-10T12:00:00Z' },
        { 'metric': 'rssi', 'value': -114, 'mean': -114, 'time': '2023-03-10T12:51:00.529Z' }]
    },
    {
        "id": 6, "latlng": [59.296572395504803, 18.081575033263785], "name": "WSP 4", "reportedAt": "2023-02-27T09:35:22.104Z", "owner": "WSP", "rssi": -115, "snr": -11, "spreadingFactor": 7,
        "timeSeries": [{ 'metric': 'rssi', 'value': -120, 'mean': -120, 'time': '2023-03-03T18:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-04T00:00:00Z' },
        { 'metric': 'rssi', 'value': -117.75, 'mean': -117.75, 'time': '2023-03-04T06:00:00Z' },
        { 'metric': 'rssi', 'value': -116, 'mean': -116, 'time': '2023-03-04T12:00:00Z' },
        { 'metric': 'rssi', 'value': -117, 'mean': -117, 'time': '2023-03-04T18:00:00Z' },
        { 'metric': 'rssi', 'value': -116.75, 'mean': -116.75, 'time': '2023-03-05T00:00:00Z' },
        { 'metric': 'rssi', 'value': -122, 'mean': -122, 'time': '2023-03-05T06:00:00Z' },
        { 'metric': 'rssi', 'value': -113, 'mean': -113, 'time': '2023-03-05T12:00:00Z' },
        { 'metric': 'rssi', 'value': -116.5, 'mean': -116.5, 'time': '2023-03-05T18:00:00Z' },
        { 'metric': 'rssi', 'value': -115.5, 'mean': -115.5, 'time': '2023-03-06T00:00:00Z' },
        { 'metric': 'rssi', 'value': -114.66666666666667, 'mean': -114.66666666666667, 'time': '2023-03-06T06:00:00Z' },
        { 'metric': 'rssi', 'value': -114.8, 'mean': -114.8, 'time': '2023-03-06T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-06T18:00:00Z' },
        { 'metric': 'rssi', 'value': -117.4, 'mean': -117.4, 'time': '2023-03-07T00:00:00Z' },
        { 'metric': 'rssi', 'value': -117.33333333333333, 'mean': -117.33333333333333, 'time': '2023-03-07T06:00:00Z' },
        { 'metric': 'rssi', 'value': -115.66666666666667, 'mean': -115.66666666666667, 'time': '2023-03-07T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-08T00:00:00Z' },
        { 'metric': 'rssi', 'value': -115, 'mean': -115, 'time': '2023-03-08T06:00:00Z' },
        { 'metric': 'rssi', 'value': -116.66666666666667, 'mean': -116.66666666666667, 'time': '2023-03-08T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119.5, 'mean': -119.5, 'time': '2023-03-08T18:00:00Z' },
        { 'metric': 'rssi', 'value': -115.66666666666667, 'mean': -115.66666666666667, 'time': '2023-03-09T00:00:00Z' },
        { 'metric': 'rssi', 'value': -117, 'mean': -117, 'time': '2023-03-09T06:00:00Z' },
        { 'metric': 'rssi', 'value': -118.33333333333333, 'mean': -118.33333333333333, 'time': '2023-03-09T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-09T18:00:00Z' },
        { 'metric': 'rssi', 'value': -119.75, 'mean': -119.75, 'time': '2023-03-10T00:00:00Z' },
        { 'metric': 'rssi', 'value': -119.33333333333333, 'mean': -119.33333333333333, 'time': '2023-03-10T06:00:00Z' },
        { 'metric': 'rssi', 'value': -117.25, 'mean': -117.25, 'time': '2023-03-10T12:00:00Z' },
        { 'metric': 'rssi', 'value': -114, 'mean': -114, 'time': '2023-03-10T12:51:00.529Z' }]
    },
    {
        "id": 7, "latlng": [59.29672156366285, 18.08198838984498], "name": "WSP 5", "reportedAt": new Date(), "owner": "WSP", "rssi": -127, "snr": 2, "spreadingFactor": 7,
        "timeSeries": [{ 'metric': 'rssi', 'value': -120, 'mean': -120, 'time': '2023-03-03T18:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-04T00:00:00Z' },
        { 'metric': 'rssi', 'value': -117.75, 'mean': -117.75, 'time': '2023-03-04T06:00:00Z' },
        { 'metric': 'rssi', 'value': -116, 'mean': -116, 'time': '2023-03-04T12:00:00Z' },
        { 'metric': 'rssi', 'value': -117, 'mean': -117, 'time': '2023-03-04T18:00:00Z' },
        { 'metric': 'rssi', 'value': -116.75, 'mean': -116.75, 'time': '2023-03-05T00:00:00Z' },
        { 'metric': 'rssi', 'value': -122, 'mean': -122, 'time': '2023-03-05T06:00:00Z' },
        { 'metric': 'rssi', 'value': -113, 'mean': -113, 'time': '2023-03-05T12:00:00Z' },
        { 'metric': 'rssi', 'value': -116.5, 'mean': -116.5, 'time': '2023-03-05T18:00:00Z' },
        { 'metric': 'rssi', 'value': -115.5, 'mean': -115.5, 'time': '2023-03-06T00:00:00Z' },
        { 'metric': 'rssi', 'value': -114.66666666666667, 'mean': -114.66666666666667, 'time': '2023-03-06T06:00:00Z' },
        { 'metric': 'rssi', 'value': -114.8, 'mean': -114.8, 'time': '2023-03-06T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-06T18:00:00Z' },
        { 'metric': 'rssi', 'value': -117.4, 'mean': -117.4, 'time': '2023-03-07T00:00:00Z' },
        { 'metric': 'rssi', 'value': -117.33333333333333, 'mean': -117.33333333333333, 'time': '2023-03-07T06:00:00Z' },
        { 'metric': 'rssi', 'value': -115.66666666666667, 'mean': -115.66666666666667, 'time': '2023-03-07T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-08T00:00:00Z' },
        { 'metric': 'rssi', 'value': -115, 'mean': -115, 'time': '2023-03-08T06:00:00Z' },
        { 'metric': 'rssi', 'value': -116.66666666666667, 'mean': -116.66666666666667, 'time': '2023-03-08T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119.5, 'mean': -119.5, 'time': '2023-03-08T18:00:00Z' },
        { 'metric': 'rssi', 'value': -115.66666666666667, 'mean': -115.66666666666667, 'time': '2023-03-09T00:00:00Z' },
        { 'metric': 'rssi', 'value': -117, 'mean': -117, 'time': '2023-03-09T06:00:00Z' },
        { 'metric': 'rssi', 'value': -118.33333333333333, 'mean': -118.33333333333333, 'time': '2023-03-09T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-09T18:00:00Z' },
        { 'metric': 'rssi', 'value': -119.75, 'mean': -119.75, 'time': '2023-03-10T00:00:00Z' },
        { 'metric': 'rssi', 'value': -119.33333333333333, 'mean': -119.33333333333333, 'time': '2023-03-10T06:00:00Z' },
        { 'metric': 'rssi', 'value': -117.25, 'mean': -117.25, 'time': '2023-03-10T12:00:00Z' },
        { 'metric': 'rssi', 'value': -114, 'mean': -114, 'time': '2023-03-10T12:51:00.529Z' }]
    },
    {
        "id": 8, "latlng": [59.29659338278649, 18.082039762549837], "name": "WSP 6", "reportedAt": new Date(), "owner": "WSP", "rssi": -114, "snr": -1, "spreadingFactor": 7,
        "timeSeries": [{ 'metric': 'rssi', 'value': -120, 'mean': -120, 'time': '2023-03-03T18:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-04T00:00:00Z' },
        { 'metric': 'rssi', 'value': -117.75, 'mean': -117.75, 'time': '2023-03-04T06:00:00Z' },
        { 'metric': 'rssi', 'value': -116, 'mean': -116, 'time': '2023-03-04T12:00:00Z' },
        { 'metric': 'rssi', 'value': -117, 'mean': -117, 'time': '2023-03-04T18:00:00Z' },
        { 'metric': 'rssi', 'value': -116.75, 'mean': -116.75, 'time': '2023-03-05T00:00:00Z' },
        { 'metric': 'rssi', 'value': -122, 'mean': -122, 'time': '2023-03-05T06:00:00Z' },
        { 'metric': 'rssi', 'value': -113, 'mean': -113, 'time': '2023-03-05T12:00:00Z' },
        { 'metric': 'rssi', 'value': -116.5, 'mean': -116.5, 'time': '2023-03-05T18:00:00Z' },
        { 'metric': 'rssi', 'value': -115.5, 'mean': -115.5, 'time': '2023-03-06T00:00:00Z' },
        { 'metric': 'rssi', 'value': -114.66666666666667, 'mean': -114.66666666666667, 'time': '2023-03-06T06:00:00Z' },
        { 'metric': 'rssi', 'value': -114.8, 'mean': -114.8, 'time': '2023-03-06T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-06T18:00:00Z' },
        { 'metric': 'rssi', 'value': -117.4, 'mean': -117.4, 'time': '2023-03-07T00:00:00Z' },
        { 'metric': 'rssi', 'value': -117.33333333333333, 'mean': -117.33333333333333, 'time': '2023-03-07T06:00:00Z' },
        { 'metric': 'rssi', 'value': -115.66666666666667, 'mean': -115.66666666666667, 'time': '2023-03-07T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-08T00:00:00Z' },
        { 'metric': 'rssi', 'value': -115, 'mean': -115, 'time': '2023-03-08T06:00:00Z' },
        { 'metric': 'rssi', 'value': -116.66666666666667, 'mean': -116.66666666666667, 'time': '2023-03-08T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119.5, 'mean': -119.5, 'time': '2023-03-08T18:00:00Z' },
        { 'metric': 'rssi', 'value': -115.66666666666667, 'mean': -115.66666666666667, 'time': '2023-03-09T00:00:00Z' },
        { 'metric': 'rssi', 'value': -117, 'mean': -117, 'time': '2023-03-09T06:00:00Z' },
        { 'metric': 'rssi', 'value': -118.33333333333333, 'mean': -118.33333333333333, 'time': '2023-03-09T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-09T18:00:00Z' },
        { 'metric': 'rssi', 'value': -119.75, 'mean': -119.75, 'time': '2023-03-10T00:00:00Z' },
        { 'metric': 'rssi', 'value': -119.33333333333333, 'mean': -119.33333333333333, 'time': '2023-03-10T06:00:00Z' },
        { 'metric': 'rssi', 'value': -117.25, 'mean': -117.25, 'time': '2023-03-10T12:00:00Z' },
        { 'metric': 'rssi', 'value': -114, 'mean': -114, 'time': '2023-03-10T12:51:00.529Z' }]
    },
    {
        "id": 9, "latlng": [59.296723150554627, 18.082398638481955], "name": "WSP 7", "reportedAt": new Date(), "owner": "WSP", "rssi": -119, "snr": 1, "spreadingFactor": 7,
        "timeSeries": [{ 'metric': 'rssi', 'value': -120, 'mean': -120, 'time': '2023-03-03T18:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-04T00:00:00Z' },
        { 'metric': 'rssi', 'value': -117.75, 'mean': -117.75, 'time': '2023-03-04T06:00:00Z' },
        { 'metric': 'rssi', 'value': -116, 'mean': -116, 'time': '2023-03-04T12:00:00Z' },
        { 'metric': 'rssi', 'value': -117, 'mean': -117, 'time': '2023-03-04T18:00:00Z' },
        { 'metric': 'rssi', 'value': -116.75, 'mean': -116.75, 'time': '2023-03-05T00:00:00Z' },
        { 'metric': 'rssi', 'value': -122, 'mean': -122, 'time': '2023-03-05T06:00:00Z' },
        { 'metric': 'rssi', 'value': -113, 'mean': -113, 'time': '2023-03-05T12:00:00Z' },
        { 'metric': 'rssi', 'value': -116.5, 'mean': -116.5, 'time': '2023-03-05T18:00:00Z' },
        { 'metric': 'rssi', 'value': -115.5, 'mean': -115.5, 'time': '2023-03-06T00:00:00Z' },
        { 'metric': 'rssi', 'value': -114.66666666666667, 'mean': -114.66666666666667, 'time': '2023-03-06T06:00:00Z' },
        { 'metric': 'rssi', 'value': -114.8, 'mean': -114.8, 'time': '2023-03-06T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-06T18:00:00Z' },
        { 'metric': 'rssi', 'value': -117.4, 'mean': -117.4, 'time': '2023-03-07T00:00:00Z' },
        { 'metric': 'rssi', 'value': -117.33333333333333, 'mean': -117.33333333333333, 'time': '2023-03-07T06:00:00Z' },
        { 'metric': 'rssi', 'value': -115.66666666666667, 'mean': -115.66666666666667, 'time': '2023-03-07T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-08T00:00:00Z' },
        { 'metric': 'rssi', 'value': -115, 'mean': -115, 'time': '2023-03-08T06:00:00Z' },
        { 'metric': 'rssi', 'value': -116.66666666666667, 'mean': -116.66666666666667, 'time': '2023-03-08T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119.5, 'mean': -119.5, 'time': '2023-03-08T18:00:00Z' },
        { 'metric': 'rssi', 'value': -115.66666666666667, 'mean': -115.66666666666667, 'time': '2023-03-09T00:00:00Z' },
        { 'metric': 'rssi', 'value': -117, 'mean': -117, 'time': '2023-03-09T06:00:00Z' },
        { 'metric': 'rssi', 'value': -118.33333333333333, 'mean': -118.33333333333333, 'time': '2023-03-09T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-09T18:00:00Z' },
        { 'metric': 'rssi', 'value': -119.75, 'mean': -119.75, 'time': '2023-03-10T00:00:00Z' },
        { 'metric': 'rssi', 'value': -119.33333333333333, 'mean': -119.33333333333333, 'time': '2023-03-10T06:00:00Z' },
        { 'metric': 'rssi', 'value': -117.25, 'mean': -117.25, 'time': '2023-03-10T12:00:00Z' },
        { 'metric': 'rssi', 'value': -114, 'mean': -114, 'time': '2023-03-10T12:51:00.529Z' }]
    },
    {
        "id": 10, "latlng": [59.296562874110805, 18.082392422593518], "name": "WSP 8", "reportedAt": new Date(), "owner": "WSP", "rssi": -56, "snr": 3, "spreadingFactor": 7,
        "timeSeries": [{ 'metric': 'rssi', 'value': -120, 'mean': -120, 'time': '2023-03-03T18:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-04T00:00:00Z' },
        { 'metric': 'rssi', 'value': -117.75, 'mean': -117.75, 'time': '2023-03-04T06:00:00Z' },
        { 'metric': 'rssi', 'value': -116, 'mean': -116, 'time': '2023-03-04T12:00:00Z' },
        { 'metric': 'rssi', 'value': -117, 'mean': -117, 'time': '2023-03-04T18:00:00Z' },
        { 'metric': 'rssi', 'value': -116.75, 'mean': -116.75, 'time': '2023-03-05T00:00:00Z' },
        { 'metric': 'rssi', 'value': -122, 'mean': -122, 'time': '2023-03-05T06:00:00Z' },
        { 'metric': 'rssi', 'value': -113, 'mean': -113, 'time': '2023-03-05T12:00:00Z' },
        { 'metric': 'rssi', 'value': -116.5, 'mean': -116.5, 'time': '2023-03-05T18:00:00Z' },
        { 'metric': 'rssi', 'value': -115.5, 'mean': -115.5, 'time': '2023-03-06T00:00:00Z' },
        { 'metric': 'rssi', 'value': -114.66666666666667, 'mean': -114.66666666666667, 'time': '2023-03-06T06:00:00Z' },
        { 'metric': 'rssi', 'value': -114.8, 'mean': -114.8, 'time': '2023-03-06T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-06T18:00:00Z' },
        { 'metric': 'rssi', 'value': -117.4, 'mean': -117.4, 'time': '2023-03-07T00:00:00Z' },
        { 'metric': 'rssi', 'value': -117.33333333333333, 'mean': -117.33333333333333, 'time': '2023-03-07T06:00:00Z' },
        { 'metric': 'rssi', 'value': -115.66666666666667, 'mean': -115.66666666666667, 'time': '2023-03-07T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-08T00:00:00Z' },
        { 'metric': 'rssi', 'value': -115, 'mean': -115, 'time': '2023-03-08T06:00:00Z' },
        { 'metric': 'rssi', 'value': -116.66666666666667, 'mean': -116.66666666666667, 'time': '2023-03-08T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119.5, 'mean': -119.5, 'time': '2023-03-08T18:00:00Z' },
        { 'metric': 'rssi', 'value': -115.66666666666667, 'mean': -115.66666666666667, 'time': '2023-03-09T00:00:00Z' },
        { 'metric': 'rssi', 'value': -117, 'mean': -117, 'time': '2023-03-09T06:00:00Z' },
        { 'metric': 'rssi', 'value': -118.33333333333333, 'mean': -118.33333333333333, 'time': '2023-03-09T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-09T18:00:00Z' },
        { 'metric': 'rssi', 'value': -119.75, 'mean': -119.75, 'time': '2023-03-10T00:00:00Z' },
        { 'metric': 'rssi', 'value': -119.33333333333333, 'mean': -119.33333333333333, 'time': '2023-03-10T06:00:00Z' },
        { 'metric': 'rssi', 'value': -117.25, 'mean': -117.25, 'time': '2023-03-10T12:00:00Z' },
        { 'metric': 'rssi', 'value': -114, 'mean': -114, 'time': '2023-03-10T12:51:00.529Z' }]
    },
    {
        "id": 11, "latlng": [59.296715216094988, 18.082877261891763], "name": "WSP 9", "reportedAt": new Date(), "owner": "WSP", "rssi": -110, "snr": -15, "spreadingFactor": 11,
        "timeSeries": [{ 'metric': 'rssi', 'value': -120, 'mean': -120, 'time': '2023-03-03T18:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-04T00:00:00Z' },
        { 'metric': 'rssi', 'value': -117.75, 'mean': -117.75, 'time': '2023-03-04T06:00:00Z' },
        { 'metric': 'rssi', 'value': -116, 'mean': -116, 'time': '2023-03-04T12:00:00Z' },
        { 'metric': 'rssi', 'value': -117, 'mean': -117, 'time': '2023-03-04T18:00:00Z' },
        { 'metric': 'rssi', 'value': -116.75, 'mean': -116.75, 'time': '2023-03-05T00:00:00Z' },
        { 'metric': 'rssi', 'value': -122, 'mean': -122, 'time': '2023-03-05T06:00:00Z' },
        { 'metric': 'rssi', 'value': -113, 'mean': -113, 'time': '2023-03-05T12:00:00Z' },
        { 'metric': 'rssi', 'value': -116.5, 'mean': -116.5, 'time': '2023-03-05T18:00:00Z' },
        { 'metric': 'rssi', 'value': -115.5, 'mean': -115.5, 'time': '2023-03-06T00:00:00Z' },
        { 'metric': 'rssi', 'value': -114.66666666666667, 'mean': -114.66666666666667, 'time': '2023-03-06T06:00:00Z' },
        { 'metric': 'rssi', 'value': -114.8, 'mean': -114.8, 'time': '2023-03-06T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-06T18:00:00Z' },
        { 'metric': 'rssi', 'value': -117.4, 'mean': -117.4, 'time': '2023-03-07T00:00:00Z' },
        { 'metric': 'rssi', 'value': -117.33333333333333, 'mean': -117.33333333333333, 'time': '2023-03-07T06:00:00Z' },
        { 'metric': 'rssi', 'value': -115.66666666666667, 'mean': -115.66666666666667, 'time': '2023-03-07T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-08T00:00:00Z' },
        { 'metric': 'rssi', 'value': -115, 'mean': -115, 'time': '2023-03-08T06:00:00Z' },
        { 'metric': 'rssi', 'value': -116.66666666666667, 'mean': -116.66666666666667, 'time': '2023-03-08T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119.5, 'mean': -119.5, 'time': '2023-03-08T18:00:00Z' },
        { 'metric': 'rssi', 'value': -115.66666666666667, 'mean': -115.66666666666667, 'time': '2023-03-09T00:00:00Z' },
        { 'metric': 'rssi', 'value': -117, 'mean': -117, 'time': '2023-03-09T06:00:00Z' },
        { 'metric': 'rssi', 'value': -118.33333333333333, 'mean': -118.33333333333333, 'time': '2023-03-09T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-09T18:00:00Z' },
        { 'metric': 'rssi', 'value': -119.75, 'mean': -119.75, 'time': '2023-03-10T00:00:00Z' },
        { 'metric': 'rssi', 'value': -119.33333333333333, 'mean': -119.33333333333333, 'time': '2023-03-10T06:00:00Z' },
        { 'metric': 'rssi', 'value': -117.25, 'mean': -117.25, 'time': '2023-03-10T12:00:00Z' },
        { 'metric': 'rssi', 'value': -114, 'mean': -114, 'time': '2023-03-10T12:51:00.529Z' }]
    },
    {
        "id": 12, "latlng": [59.296556526513328, 18.082877261891763], "name": "WSP 10", "reportedAt": new Date(), "owner": "WSP", "rssi": -112, "snr": -5, "spreadingFactor": 11,
        "timeSeries": [{ 'metric': 'rssi', 'value': -120, 'mean': -120, 'time': '2023-03-03T18:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-04T00:00:00Z' },
        { 'metric': 'rssi', 'value': -117.75, 'mean': -117.75, 'time': '2023-03-04T06:00:00Z' },
        { 'metric': 'rssi', 'value': -116, 'mean': -116, 'time': '2023-03-04T12:00:00Z' },
        { 'metric': 'rssi', 'value': -117, 'mean': -117, 'time': '2023-03-04T18:00:00Z' },
        { 'metric': 'rssi', 'value': -116.75, 'mean': -116.75, 'time': '2023-03-05T00:00:00Z' },
        { 'metric': 'rssi', 'value': -122, 'mean': -122, 'time': '2023-03-05T06:00:00Z' },
        { 'metric': 'rssi', 'value': -113, 'mean': -113, 'time': '2023-03-05T12:00:00Z' },
        { 'metric': 'rssi', 'value': -116.5, 'mean': -116.5, 'time': '2023-03-05T18:00:00Z' },
        { 'metric': 'rssi', 'value': -115.5, 'mean': -115.5, 'time': '2023-03-06T00:00:00Z' },
        { 'metric': 'rssi', 'value': -114.66666666666667, 'mean': -114.66666666666667, 'time': '2023-03-06T06:00:00Z' },
        { 'metric': 'rssi', 'value': -114.8, 'mean': -114.8, 'time': '2023-03-06T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-06T18:00:00Z' },
        { 'metric': 'rssi', 'value': -117.4, 'mean': -117.4, 'time': '2023-03-07T00:00:00Z' },
        { 'metric': 'rssi', 'value': -117.33333333333333, 'mean': -117.33333333333333, 'time': '2023-03-07T06:00:00Z' },
        { 'metric': 'rssi', 'value': -115.66666666666667, 'mean': -115.66666666666667, 'time': '2023-03-07T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-08T00:00:00Z' },
        { 'metric': 'rssi', 'value': -115, 'mean': -115, 'time': '2023-03-08T06:00:00Z' },
        { 'metric': 'rssi', 'value': -116.66666666666667, 'mean': -116.66666666666667, 'time': '2023-03-08T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119.5, 'mean': -119.5, 'time': '2023-03-08T18:00:00Z' },
        { 'metric': 'rssi', 'value': -115.66666666666667, 'mean': -115.66666666666667, 'time': '2023-03-09T00:00:00Z' },
        { 'metric': 'rssi', 'value': -117, 'mean': -117, 'time': '2023-03-09T06:00:00Z' },
        { 'metric': 'rssi', 'value': -118.33333333333333, 'mean': -118.33333333333333, 'time': '2023-03-09T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-09T18:00:00Z' },
        { 'metric': 'rssi', 'value': -119.75, 'mean': -119.75, 'time': '2023-03-10T00:00:00Z' },
        { 'metric': 'rssi', 'value': -119.33333333333333, 'mean': -119.33333333333333, 'time': '2023-03-10T06:00:00Z' },
        { 'metric': 'rssi', 'value': -117.25, 'mean': -117.25, 'time': '2023-03-10T12:00:00Z' },
        { 'metric': 'rssi', 'value': -114, 'mean': -114, 'time': '2023-03-10T12:51:00.529Z' }]
    },
    {
        "id": 13, "latlng": [59.296653327246176, 18.081155460794147], "name": "WSP 11", "reportedAt": "2023-02-27T09:35:22.104Z", "owner": "WSP", "rssi": -116, "snr": -5, "spreadingFactor": 11,
        "timeSeries": [{ 'metric': 'rssi', 'value': -120, 'mean': -120, 'time': '2023-03-03T18:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-04T00:00:00Z' },
        { 'metric': 'rssi', 'value': -117.75, 'mean': -117.75, 'time': '2023-03-04T06:00:00Z' },
        { 'metric': 'rssi', 'value': -116, 'mean': -116, 'time': '2023-03-04T12:00:00Z' },
        { 'metric': 'rssi', 'value': -117, 'mean': -117, 'time': '2023-03-04T18:00:00Z' },
        { 'metric': 'rssi', 'value': -116.75, 'mean': -116.75, 'time': '2023-03-05T00:00:00Z' },
        { 'metric': 'rssi', 'value': -122, 'mean': -122, 'time': '2023-03-05T06:00:00Z' },
        { 'metric': 'rssi', 'value': -113, 'mean': -113, 'time': '2023-03-05T12:00:00Z' },
        { 'metric': 'rssi', 'value': -116.5, 'mean': -116.5, 'time': '2023-03-05T18:00:00Z' },
        { 'metric': 'rssi', 'value': -115.5, 'mean': -115.5, 'time': '2023-03-06T00:00:00Z' },
        { 'metric': 'rssi', 'value': -114.66666666666667, 'mean': -114.66666666666667, 'time': '2023-03-06T06:00:00Z' },
        { 'metric': 'rssi', 'value': -114.8, 'mean': -114.8, 'time': '2023-03-06T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-06T18:00:00Z' },
        { 'metric': 'rssi', 'value': -117.4, 'mean': -117.4, 'time': '2023-03-07T00:00:00Z' },
        { 'metric': 'rssi', 'value': -117.33333333333333, 'mean': -117.33333333333333, 'time': '2023-03-07T06:00:00Z' },
        { 'metric': 'rssi', 'value': -115.66666666666667, 'mean': -115.66666666666667, 'time': '2023-03-07T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-08T00:00:00Z' },
        { 'metric': 'rssi', 'value': -115, 'mean': -115, 'time': '2023-03-08T06:00:00Z' },
        { 'metric': 'rssi', 'value': -116.66666666666667, 'mean': -116.66666666666667, 'time': '2023-03-08T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119.5, 'mean': -119.5, 'time': '2023-03-08T18:00:00Z' },
        { 'metric': 'rssi', 'value': -115.66666666666667, 'mean': -115.66666666666667, 'time': '2023-03-09T00:00:00Z' },
        { 'metric': 'rssi', 'value': -117, 'mean': -117, 'time': '2023-03-09T06:00:00Z' },
        { 'metric': 'rssi', 'value': -118.33333333333333, 'mean': -118.33333333333333, 'time': '2023-03-09T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-09T18:00:00Z' },
        { 'metric': 'rssi', 'value': -119.75, 'mean': -119.75, 'time': '2023-03-10T00:00:00Z' },
        { 'metric': 'rssi', 'value': -119.33333333333333, 'mean': -119.33333333333333, 'time': '2023-03-10T06:00:00Z' },
        { 'metric': 'rssi', 'value': -117.25, 'mean': -117.25, 'time': '2023-03-10T12:00:00Z' },
        { 'metric': 'rssi', 'value': -114, 'mean': -114, 'time': '2023-03-10T12:51:00.529Z' }]
    },
    {
        "id": 14, "latlng": [59.296650153456021, 18.0814662552161], "name": "WSP 12", "reportedAt": new Date(), "owner": "WSP", "rssi": -90, "snr": -8, "spreadingFactor": 11,
        "timeSeries": [{ 'metric': 'rssi', 'value': -120, 'mean': -120, 'time': '2023-03-03T18:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-04T00:00:00Z' },
        { 'metric': 'rssi', 'value': -117.75, 'mean': -117.75, 'time': '2023-03-04T06:00:00Z' },
        { 'metric': 'rssi', 'value': -116, 'mean': -116, 'time': '2023-03-04T12:00:00Z' },
        { 'metric': 'rssi', 'value': -117, 'mean': -117, 'time': '2023-03-04T18:00:00Z' },
        { 'metric': 'rssi', 'value': -116.75, 'mean': -116.75, 'time': '2023-03-05T00:00:00Z' },
        { 'metric': 'rssi', 'value': -122, 'mean': -122, 'time': '2023-03-05T06:00:00Z' },
        { 'metric': 'rssi', 'value': -113, 'mean': -113, 'time': '2023-03-05T12:00:00Z' },
        { 'metric': 'rssi', 'value': -116.5, 'mean': -116.5, 'time': '2023-03-05T18:00:00Z' },
        { 'metric': 'rssi', 'value': -115.5, 'mean': -115.5, 'time': '2023-03-06T00:00:00Z' },
        { 'metric': 'rssi', 'value': -114.66666666666667, 'mean': -114.66666666666667, 'time': '2023-03-06T06:00:00Z' },
        { 'metric': 'rssi', 'value': -114.8, 'mean': -114.8, 'time': '2023-03-06T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-06T18:00:00Z' },
        { 'metric': 'rssi', 'value': -117.4, 'mean': -117.4, 'time': '2023-03-07T00:00:00Z' },
        { 'metric': 'rssi', 'value': -117.33333333333333, 'mean': -117.33333333333333, 'time': '2023-03-07T06:00:00Z' },
        { 'metric': 'rssi', 'value': -115.66666666666667, 'mean': -115.66666666666667, 'time': '2023-03-07T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-08T00:00:00Z' },
        { 'metric': 'rssi', 'value': -115, 'mean': -115, 'time': '2023-03-08T06:00:00Z' },
        { 'metric': 'rssi', 'value': -116.66666666666667, 'mean': -116.66666666666667, 'time': '2023-03-08T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119.5, 'mean': -119.5, 'time': '2023-03-08T18:00:00Z' },
        { 'metric': 'rssi', 'value': -115.66666666666667, 'mean': -115.66666666666667, 'time': '2023-03-09T00:00:00Z' },
        { 'metric': 'rssi', 'value': -117, 'mean': -117, 'time': '2023-03-09T06:00:00Z' },
        { 'metric': 'rssi', 'value': -118.33333333333333, 'mean': -118.33333333333333, 'time': '2023-03-09T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-09T18:00:00Z' },
        { 'metric': 'rssi', 'value': -119.75, 'mean': -119.75, 'time': '2023-03-10T00:00:00Z' },
        { 'metric': 'rssi', 'value': -119.33333333333333, 'mean': -119.33333333333333, 'time': '2023-03-10T06:00:00Z' },
        { 'metric': 'rssi', 'value': -117.25, 'mean': -117.25, 'time': '2023-03-10T12:00:00Z' },
        { 'metric': 'rssi', 'value': -114, 'mean': -114, 'time': '2023-03-10T12:51:00.529Z' }]
    },
    {
        "id": 15, "latlng": [59.296640632083793, 18.081777049638053], "name": "WSP 13", "reportedAt": "2023-02-27T09:35:22.104Z", "owner": "WSP", "rssi": -105, "snr": -2, "spreadingFactor": 11,
        "timeSeries": [{ 'metric': 'rssi', 'value': -120, 'mean': -120, 'time': '2023-03-03T18:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-04T00:00:00Z' },
        { 'metric': 'rssi', 'value': -117.75, 'mean': -117.75, 'time': '2023-03-04T06:00:00Z' },
        { 'metric': 'rssi', 'value': -116, 'mean': -116, 'time': '2023-03-04T12:00:00Z' },
        { 'metric': 'rssi', 'value': -117, 'mean': -117, 'time': '2023-03-04T18:00:00Z' },
        { 'metric': 'rssi', 'value': -116.75, 'mean': -116.75, 'time': '2023-03-05T00:00:00Z' },
        { 'metric': 'rssi', 'value': -122, 'mean': -122, 'time': '2023-03-05T06:00:00Z' },
        { 'metric': 'rssi', 'value': -113, 'mean': -113, 'time': '2023-03-05T12:00:00Z' },
        { 'metric': 'rssi', 'value': -116.5, 'mean': -116.5, 'time': '2023-03-05T18:00:00Z' },
        { 'metric': 'rssi', 'value': -115.5, 'mean': -115.5, 'time': '2023-03-06T00:00:00Z' },
        { 'metric': 'rssi', 'value': -114.66666666666667, 'mean': -114.66666666666667, 'time': '2023-03-06T06:00:00Z' },
        { 'metric': 'rssi', 'value': -114.8, 'mean': -114.8, 'time': '2023-03-06T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-06T18:00:00Z' },
        { 'metric': 'rssi', 'value': -117.4, 'mean': -117.4, 'time': '2023-03-07T00:00:00Z' },
        { 'metric': 'rssi', 'value': -117.33333333333333, 'mean': -117.33333333333333, 'time': '2023-03-07T06:00:00Z' },
        { 'metric': 'rssi', 'value': -115.66666666666667, 'mean': -115.66666666666667, 'time': '2023-03-07T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-08T00:00:00Z' },
        { 'metric': 'rssi', 'value': -115, 'mean': -115, 'time': '2023-03-08T06:00:00Z' },
        { 'metric': 'rssi', 'value': -116.66666666666667, 'mean': -116.66666666666667, 'time': '2023-03-08T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119.5, 'mean': -119.5, 'time': '2023-03-08T18:00:00Z' },
        { 'metric': 'rssi', 'value': -115.66666666666667, 'mean': -115.66666666666667, 'time': '2023-03-09T00:00:00Z' },
        { 'metric': 'rssi', 'value': -117, 'mean': -117, 'time': '2023-03-09T06:00:00Z' },
        { 'metric': 'rssi', 'value': -118.33333333333333, 'mean': -118.33333333333333, 'time': '2023-03-09T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-09T18:00:00Z' },
        { 'metric': 'rssi', 'value': -119.75, 'mean': -119.75, 'time': '2023-03-10T00:00:00Z' },
        { 'metric': 'rssi', 'value': -119.33333333333333, 'mean': -119.33333333333333, 'time': '2023-03-10T06:00:00Z' },
        { 'metric': 'rssi', 'value': -117.25, 'mean': -117.25, 'time': '2023-03-10T12:00:00Z' },
        { 'metric': 'rssi', 'value': -114, 'mean': -114, 'time': '2023-03-10T12:51:00.529Z' }]
    },
    {
        "id": 16, "latlng": [59.296645392770237, 18.082184190330807], "name": "WSP 143", "reportedAt": "2023-02-27T09:35:22.104Z", "owner": "WSP", "rssi": -108, "snr": -4, "spreadingFactor": 11,
        "timeSeries": [{ 'metric': 'rssi', 'value': -120, 'mean': -120, 'time': '2023-03-03T18:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-04T00:00:00Z' },
        { 'metric': 'rssi', 'value': -117.75, 'mean': -117.75, 'time': '2023-03-04T06:00:00Z' },
        { 'metric': 'rssi', 'value': -116, 'mean': -116, 'time': '2023-03-04T12:00:00Z' },
        { 'metric': 'rssi', 'value': -117, 'mean': -117, 'time': '2023-03-04T18:00:00Z' },
        { 'metric': 'rssi', 'value': -116.75, 'mean': -116.75, 'time': '2023-03-05T00:00:00Z' },
        { 'metric': 'rssi', 'value': -122, 'mean': -122, 'time': '2023-03-05T06:00:00Z' },
        { 'metric': 'rssi', 'value': -113, 'mean': -113, 'time': '2023-03-05T12:00:00Z' },
        { 'metric': 'rssi', 'value': -116.5, 'mean': -116.5, 'time': '2023-03-05T18:00:00Z' },
        { 'metric': 'rssi', 'value': -115.5, 'mean': -115.5, 'time': '2023-03-06T00:00:00Z' },
        { 'metric': 'rssi', 'value': -114.66666666666667, 'mean': -114.66666666666667, 'time': '2023-03-06T06:00:00Z' },
        { 'metric': 'rssi', 'value': -114.8, 'mean': -114.8, 'time': '2023-03-06T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-06T18:00:00Z' },
        { 'metric': 'rssi', 'value': -117.4, 'mean': -117.4, 'time': '2023-03-07T00:00:00Z' },
        { 'metric': 'rssi', 'value': -117.33333333333333, 'mean': -117.33333333333333, 'time': '2023-03-07T06:00:00Z' },
        { 'metric': 'rssi', 'value': -115.66666666666667, 'mean': -115.66666666666667, 'time': '2023-03-07T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-08T00:00:00Z' },
        { 'metric': 'rssi', 'value': -115, 'mean': -115, 'time': '2023-03-08T06:00:00Z' },
        { 'metric': 'rssi', 'value': -116.66666666666667, 'mean': -116.66666666666667, 'time': '2023-03-08T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119.5, 'mean': -119.5, 'time': '2023-03-08T18:00:00Z' },
        { 'metric': 'rssi', 'value': -115.66666666666667, 'mean': -115.66666666666667, 'time': '2023-03-09T00:00:00Z' },
        { 'metric': 'rssi', 'value': -117, 'mean': -117, 'time': '2023-03-09T06:00:00Z' },
        { 'metric': 'rssi', 'value': -118.33333333333333, 'mean': -118.33333333333333, 'time': '2023-03-09T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-09T18:00:00Z' },
        { 'metric': 'rssi', 'value': -119.75, 'mean': -119.75, 'time': '2023-03-10T00:00:00Z' },
        { 'metric': 'rssi', 'value': -119.33333333333333, 'mean': -119.33333333333333, 'time': '2023-03-10T06:00:00Z' },
        { 'metric': 'rssi', 'value': -117.25, 'mean': -117.25, 'time': '2023-03-10T12:00:00Z' },
        { 'metric': 'rssi', 'value': -114, 'mean': -114, 'time': '2023-03-10T12:51:00.529Z' }]
    },
    {
        "id": 17, "latlng": [59.296645392770237, 18.082641058131077], "name": "WSP 15", "reportedAt": "2023-02-27T09:35:22.104Z", "owner": "WSP", "rssi": -111, "snr": -4, "spreadingFactor": 11,
        "timeSeries": [{ 'metric': 'rssi', 'value': -120, 'mean': -120, 'time': '2023-03-03T18:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-04T00:00:00Z' },
        { 'metric': 'rssi', 'value': -117.75, 'mean': -117.75, 'time': '2023-03-04T06:00:00Z' },
        { 'metric': 'rssi', 'value': -116, 'mean': -116, 'time': '2023-03-04T12:00:00Z' },
        { 'metric': 'rssi', 'value': -117, 'mean': -117, 'time': '2023-03-04T18:00:00Z' },
        { 'metric': 'rssi', 'value': -116.75, 'mean': -116.75, 'time': '2023-03-05T00:00:00Z' },
        { 'metric': 'rssi', 'value': -122, 'mean': -122, 'time': '2023-03-05T06:00:00Z' },
        { 'metric': 'rssi', 'value': -113, 'mean': -113, 'time': '2023-03-05T12:00:00Z' },
        { 'metric': 'rssi', 'value': -116.5, 'mean': -116.5, 'time': '2023-03-05T18:00:00Z' },
        { 'metric': 'rssi', 'value': -115.5, 'mean': -115.5, 'time': '2023-03-06T00:00:00Z' },
        { 'metric': 'rssi', 'value': -114.66666666666667, 'mean': -114.66666666666667, 'time': '2023-03-06T06:00:00Z' },
        { 'metric': 'rssi', 'value': -114.8, 'mean': -114.8, 'time': '2023-03-06T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-06T18:00:00Z' },
        { 'metric': 'rssi', 'value': -117.4, 'mean': -117.4, 'time': '2023-03-07T00:00:00Z' },
        { 'metric': 'rssi', 'value': -117.33333333333333, 'mean': -117.33333333333333, 'time': '2023-03-07T06:00:00Z' },
        { 'metric': 'rssi', 'value': -115.66666666666667, 'mean': -115.66666666666667, 'time': '2023-03-07T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-08T00:00:00Z' },
        { 'metric': 'rssi', 'value': -115, 'mean': -115, 'time': '2023-03-08T06:00:00Z' },
        { 'metric': 'rssi', 'value': -116.66666666666667, 'mean': -116.66666666666667, 'time': '2023-03-08T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119.5, 'mean': -119.5, 'time': '2023-03-08T18:00:00Z' },
        { 'metric': 'rssi', 'value': -115.66666666666667, 'mean': -115.66666666666667, 'time': '2023-03-09T00:00:00Z' },
        { 'metric': 'rssi', 'value': -117, 'mean': -117, 'time': '2023-03-09T06:00:00Z' },
        { 'metric': 'rssi', 'value': -118.33333333333333, 'mean': -118.33333333333333, 'time': '2023-03-09T12:00:00Z' },
        { 'metric': 'rssi', 'value': -119, 'mean': -119, 'time': '2023-03-09T18:00:00Z' },
        { 'metric': 'rssi', 'value': -119.75, 'mean': -119.75, 'time': '2023-03-10T00:00:00Z' },
        { 'metric': 'rssi', 'value': -119.33333333333333, 'mean': -119.33333333333333, 'time': '2023-03-10T06:00:00Z' },
        { 'metric': 'rssi', 'value': -117.25, 'mean': -117.25, 'time': '2023-03-10T12:00:00Z' },
        { 'metric': 'rssi', 'value': -114, 'mean': -114, 'time': '2023-03-10T12:51:00.529Z' }]
    },
    { "id": 18, "latlng": [59.29420806769485, 18.082578667054886], "name": "Globen 1", "reportedAt": new Date(), "owner": "Globen", "rssi": -90, "snr": 5, "spreadingFactor": 11 },
    { "id": 19, "latlng": [59.29422378851455, 18.0840410553845], "name": "Globen 2", "reportedAt": new Date(), "owner": "Globen", "rssi": -100, "snr": 3, "spreadingFactor": 11 },
    { "id": 20, "latlng": [59.2930368461997, 18.08379475840267], "name": "Globen 3", "reportedAt": new Date(), "owner": "Globen", "rssi": -105, "snr": 1, "spreadingFactor": 11 },
    { "id": 21, "latlng": [59.29358315922887, 18.082109163433266], "name": "Globen 4", "reportedAt": new Date(), "owner": "Globen", "rssi": -110, "snr": -1, "spreadingFactor": 7 },
    { "id": 22, "latlng": [59.29407444043403, 18.08309435136059], "name": "Globen 5", "reportedAt": new Date(), "owner": "Globen", "rssi": -115, "snr": -3, "spreadingFactor": 11 },
    { "id": 23, "latlng": [59.293598880337285, 18.084048752165184], "name": "Globen 6", "reportedAt": new Date(), "owner": "Globen", "rssi": -120, "snr": -5, "spreadingFactor": 7 },
    { "id": 24, "latlng": [59.293072219251314, 18.083071261018542], "name": "Globen 7", "reportedAt": new Date(), "owner": "Globen", "rssi": -125, "snr": -7, "spreadingFactor": 11 },
    { "id": 25, "latlng": [59.2935857621778, 18.0421877404405], "name": "Årstafältet 1", "reportedAt": new Date(), "owner": "Miljöförvaltningen", "rssi": -90, "snr": 4, "spreadingFactor": 7 },
    { "id": 26, "latlng": [59.29280195903615, 18.04245087087163], "name": "Årstafältet 2", "reportedAt": new Date(), "owner": "Miljöförvaltningen", "rssi": -93, "snr": 3, "spreadingFactor": 11 },
    { "id": 27, "latlng": [59.29190616191042, 18.042714001302755], "name": "Årstafältet 3", "reportedAt": new Date(), "owner": "Miljöförvaltningen", "rssi": -92, "snr": 2, "spreadingFactor": 7 },
    { "id": 28, "latlng": [59.291726999655594, 18.044928682431408], "name": "Årstafältet 4", "reportedAt": new Date(), "owner": "Miljöförvaltningen", "rssi": -89, "snr": 5, "spreadingFactor": 11 },
    { "id": 29, "latlng": [59.29089836195744, 18.047253001239696], "name": "Årstafältet 5", "reportedAt": new Date(), "owner": "Miljöförvaltningen", "rssi": -93, "snr": 4, "spreadingFactor": 7 },
    { "id": 30, "latlng": [59.28978974753804, 18.049577320047984], "name": "Årstafältet 6", "reportedAt": new Date(), "owner": "Miljöförvaltningen", "rssi": -94, "snr": 4, "spreadingFactor": 11 },
    { "id": 31, "latlng": [59.30843729528278, 18.101901152846377], "name": "Hammarby Sjö 1", "reportedAt": new Date(), "owner": "Globen", "rssi": -90, "snr": 5, "spreadingFactor": 11 },
    { "id": 32, "latlng": [59.307727673752645, 18.105477184420053], "name": "Hammarby Sjö 2", "reportedAt": new Date(), "owner": "Globen", "rssi": -100, "snr": 3, "spreadingFactor": 11 },
    { "id": 33, "latlng": [59.30670895697599, 18.106020699057396], "name": "Hammarby Sjö 3", "reportedAt": new Date(), "owner": "Globen", "rssi": -105, "snr": 1, "spreadingFactor": 11 },
    { "id": 34, "latlng": [59.30622799228899, 18.102071170052476], "name": "Hammarby Sjö 4", "reportedAt": new Date(), "owner": "Globen", "rssi": -110, "snr": -1, "spreadingFactor": 7 },
    { "id": 35, "latlng": [59.30608758562271, 18.09857939060258], "name": "Hammarby Sjö 5", "reportedAt": new Date(), "owner": "Globen", "rssi": -115, "snr": -3, "spreadingFactor": 11 },
    { "id": 36, "latlng": [59.30746653057116, 18.095891734223827], "name": "Hammarby Sjö 6", "reportedAt": new Date(), "owner": "Globen", "rssi": -120, "snr": -5, "spreadingFactor": 7 },
    { "id": 37, "latlng": [59.293072219251314, 18.083071261018542], "name": "Hammarby Sjö 7", "reportedAt": new Date(), "owner": "Globen", "rssi": -125, "snr": -7, "spreadingFactor": 11 },
    { "id": 38, "latlng": [59.32250225672035, 17.99797818286096], "name": "Stora Essingen 1", "reportedAt": new Date(), "owner": "Globen", "rssi": -119, "snr": 5, "spreadingFactor": 11 },
    { "id": 39, "latlng": [59.321446277133376, 17.99830933220355], "name": "Stora Essingen 2", "reportedAt": new Date(), "owner": "Globen", "rssi": -104, "snr": 3, "spreadingFactor": 11 },
    { "id": 40, "latlng": [59.319788322995535, 17.997605639850548], "name": "Stora Essingen 3", "reportedAt": new Date(), "owner": "Globen", "rssi": -109, "snr": 1, "spreadingFactor": 11 },
    { "id": 41, "latlng": [59.32309359095927, 18.00182779396854], "name": "Lilla Essingen 1", "reportedAt": new Date(), "owner": "Globen", "rssi": -121, "snr": -1, "spreadingFactor": 11 },
    { "id": 42, "latlng": [59.32236498122924, 18.004725350716186], "name": "Lilla Essingen 2", "reportedAt": new Date(), "owner": "Globen", "rssi": -100, "snr": 3, "spreadingFactor": 11 },
    { "id": 43, "latlng": [59.32367435564426, 18.008533568155947], "name": "Lilla Essingen 3", "reportedAt": new Date(), "owner": "Globen", "rssi": -105, "snr": 1, "spreadingFactor": 11 },
];

export default dummyDevices;