import dummyDevices from "../data/dummyDevices";
import { MarkerModel } from "./MarkerModel";
import { MarkerType } from "../../../models/CoreTypes";
import * as _ from "lodash";
import { SensorStatistics } from "../../dashboardPage/models/deviceStatistics";

export let sensorRSSI: MarkerModel[] = []; let rssiCount = 0;
export let sensorSNR: MarkerModel[] = []; let snrCount = 0;

let mapdata = dummyDevices.map(r => {
  
  let mrkModel = new MarkerModel(MarkerType.Sensor, r.latlng, r.id, r.rssi, r.snr, r.spreadingFactor, r.owner, r.reportedAt, r.name, r.timeSeries);
  sensorSNR[++snrCount] = _.cloneDeep(mrkModel.getSNRMarkerModel);
  sensorRSSI[++rssiCount] = _.cloneDeep(mrkModel.getRSSIMarkerModel);

  return mrkModel;
});

export let sensorStat = new SensorStatistics(sensorRSSI);

export default mapdata;

