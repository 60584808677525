let gatewayData = {
    "type": "FeatureCollection", 
    "features": 
    [
        {
            "type": "Feature", 
            "geometry": {
                "type": "Point", 
                "coordinates": [18.08190455649912,59.29654471568284]
            }, 
            "properties": {
                "name": "WSP Office Gateway", 
                "model": "Multitech Conduit AP"
            }
        },
        {
            "type": "Feature", 
            "geometry": {
                "type": "Point", 
                "coordinates": [18.016758889619442,59.318329351950396]
            }, 
            "properties": {
                "name": "Reimersholme", 
                "model": "Multitech Conduit"
            }
        }
    ]
};

export default gatewayData;
