import { useState } from "react";
import { Card, Grid, Tab, TabList, Text, Title } from "@tremor/react";
import './style/Dashboard.css';
import { sensorRSSI, sensorStat } from "../mapPage/models/mapdata";
import SensorSummary from "./components/SensorSummary";
import _ from "lodash";
import SensorDetails from "./components/SensorDetails";

const DashboardPage = () => {
  const [selectedView, setSelectedView] = useState(1);

  return (
    <main className="bg-slate-50 p-6 sm:p-10">
      <Title>Dashboard</Title>
      <Text>Sample Dashboard for WSP Smart Signal</Text>

      <TabList
        defaultValue="1"
        onValueChange={(value) => setSelectedView(_.toNumber(value))}
        className="mt-6"
      >
        <Tab value="1" text="Overview" />
        <Tab value="2" text="Detail" />
      </TabList>

      {selectedView === 1 ? (
        <>
          <Grid
            numColsLg={3}
            numColsSm={3}
            className="mt-6 gap-6"
          >
            <Card>
              {/* Placeholder to set height */}
              <div className="h-28" />
              <Title>Show Metric 1</Title>
            </Card>
            <Card>
              {/* Placeholder to set height */}
              <div className="h-28" />
              <Title>Show Metric 2</Title>
            </Card>
            <Card>
              {/* Placeholder to set height */}
              <div className="h-28" />
              <Title>Show Metric 3</Title>
            </Card>
          </Grid>


          <div className="mt-6">
            <Card>
              <SensorSummary sensorMetrics={sensorStat.metricsByOwner} />
            </Card>
          </div>

        </>
      ) : (
        <Card className="mt-6">
          {/* <div className="h-96" /> */}
          <SensorDetails markerModels={sensorRSSI}/>
        </Card>
      )}
    </main>
  );
};

export default DashboardPage;
