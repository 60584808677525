import { API, graphqlOperation } from "aws-amplify";
import { listDimDocks30s } from "../../graphql/queries";
import { RolesAPI } from "./rolesAPI";

export const OrganizationAPI = {
  async getOrganizationByName(organizationName: string) {
    /**
     * TODO: Remove organization Id from parameter and update the interface to get the data
     */
    const queryVariables = {
      limit: 5000000,
      filter: {
        pk: {
          eq: "ORGANIZATION",
        },
        sk: {
          notContains: "PROJECT",
        },
        OrganizationName: {
          eq: organizationName,
        },
      },
    };
    return new Promise(async (resolve, reject) => {
      try {
        const result = await API.graphql(
          graphqlOperation(listDimDocks30s, queryVariables)
        );
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  },
  async getUserAdminAPI(organizationId: string, userId: string) {
    const queryVariables = {
      limit: 5000000,
      filter: {
        pk: {
          eq: `ORGANIZATION#${organizationId}`,
        },
        sk: {
          eq: `USER#${userId}`,
        },
      },
    };
    return new Promise(async (resolve, reject) => {
      try {
        const result: any = await API.graphql(
          graphqlOperation(listDimDocks30s, queryVariables)
        );
        const roleId = result.data.listDimDocks30s.items[0]?.RoleId;
        if (roleId) {
          RolesAPI.getUserRole(roleId).then((res: any) => {
            if (
              res.data.listDimDocks30s.items[0].RoleName ===
              "Organization Admin"
            ) {
              resolve(true);
            } else {
              resolve(false);
            }
          });
        } else {
          resolve(false);
        }
      } catch (error) {
        reject(error);
      }
    });
  },
};
