import { MenuRounded } from "@mui/icons-material";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { Button, Dropdown, Layout, Typography, theme } from "antd";
import { isEmpty } from "lodash";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import Theme from "../theme/Theme";
import { MOBILE_MIN_WIDTH } from "../utils/contants";
import { getGlobalRoleColor, getPlatformRole } from "../utils/helpers";
import { IContextData } from "../utils/interface";
import NavBarStyles, { NavBarStylesMobile } from "./NavBar.css";
import { useMenuContext } from "../context/MenuContext";

const { Header } = Layout;
const { Title } = Typography;
const { useToken } = theme;

const NavBar = () => {
  const { token } = useToken();
  const { expanded, setExpanded } = useMenuContext();
  const navigate = useNavigate();
  const { username, userRole, logout }: IContextData = useAuth();
  const isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
  const items = !isEmpty(username)
    ? [
        {
          key: "1",
          label: (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: Theme.token.navBgPrimary,
                border: `1px solid ${Theme.token.navBorderPrimary}`,
                padding: "0.5rem",
                borderRadius: "4px",
                margin: "0.5rem",
              }}
            >
              <span>Hi, {username}</span>
              <span
                style={{
                  fontWeight: "500",
                  color: getGlobalRoleColor(getPlatformRole(userRole)),
                }}
              >
                {getPlatformRole(userRole)}
              </span>
            </div>
          ),
        },
        {
          key: "2",
          label: (
            <div
              style={{
                margin: "0.25rem 0.5rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                onClick={() => {
                  logout();
                }}
                type="link"
              >
                Sign Out
              </Button>
            </div>
          ),
        },
      ]
    : [
        {
          key: "1",
          label: (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: Theme.token.navBgPrimary,
                border: `1px solid ${Theme.token.navBorderPrimary}`,
                padding: "0.5rem",
                borderRadius: "4px",
                margin: "0.5rem",
              }}
            >
              <span>Welcome to DimDocks!</span>
            </div>
          ),
        },
        {
          key: "2",
          label: (
            <div
              style={{
                margin: "0.25rem 0.5rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button type="link" onClick={() => navigate("/login")}>
                Sign In
              </Button>
            </div>
          ),
        },
      ];

  return (
    <Header style={isMobile ? NavBarStylesMobile.header : NavBarStyles.header}>
      <div
        style={
          isMobile ? NavBarStylesMobile.navContainer : NavBarStyles.navContainer
        }
      >
        {!isEmpty(username) && !isEmpty(userRole) ? (
          <Button
            aria-label="side menu"
            className="round-header-btn"
            shape="circle"
            size="large"
            style={{
              boxShadow: "none",
            }}
            icon={
              <MenuRounded style={{ color: Theme.app.textColorSecondary }} />
            }
            onClick={() => setExpanded(!expanded)}
          />
        ) : null}
        <div style={NavBarStyles.textContainer}>
          <Title
            level={isMobile ? 4 : 3}
            style={{
              padding: "0 0 0 1rem",
              margin: "0",
              display: "inline",
              lineHeight: "0",
              color: token.colorPrimary,
            }}
          >
            WSP Smart Signal
          </Title>
        </div>
      </div>

      <div
        style={
          isMobile
            ? NavBarStylesMobile.accountContainer
            : NavBarStyles.accountContainer
        }
      >
        <Dropdown
          overlayClassName="dropdown-body-account-selector"
          menu={{ items }}
          placement="bottomRight"
          arrow
          trigger={["click"]}
        >
          <Button
            aria-label="Your Account"
            className="round-header-btn"
            shape="circle"
            size="large"
            icon={
              <AccountCircleOutlinedIcon
                style={{ color: Theme.app.textColorSecondary }}
              />
            }
          />
        </Dropdown>
      </div>
    </Header>
  );
};

export default NavBar;
