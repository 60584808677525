import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import { Button } from "antd";
import type { GeoJsonObject } from "geojson";
import L, { LatLng } from "leaflet";
import {
  GeoJSON,
  LayerGroup,
  LayersControl,
  MapContainer,
  Marker,
  Popup,
  TileLayer,
} from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import { Legend, LegendIcon } from "./components/Legend";
import { TabPopup } from "./components/TabPopup";
import "./components/legend.css";
import "leaflet/dist/leaflet.css";
import gatewayData from "./data/gatewaysData";
import mapConfig from "./data/mapConfig";
import { sensorRSSI, sensorSNR } from "./models/mapdata";
import { MarkerStyles } from "./models/markerStyle";
import "./style/map.css";
import { useMenuContext } from "../../context/MenuContext";

function MapPage() {
  const ZOOM_LEVEL = 12;
  const center: any = [59.296, 18.082];
  const { expanded, setExpanded } = useMenuContext();

  function onEachFeatureOfGatewayGeoJson({ properties }: any, layer: any) {
    layer
      .bindPopup(
        `<b>Name:</b> ${properties.name} <br><b>Model:</b> ${properties.model}`
      )
      .setZIndexOffset(4000);
  }

  function setGatewayMarker({ properties }: any, latlng: LatLng) {
    return L.marker(latlng, { icon: MarkerStyles.GatewayIcon });
  }

  return (
    <div
      className="map-view-container"
      style={{ position: "relative", height: "100%" }}
    >
      <Button
        style={{
          height: "50px",
          position: "absolute",
          top: 0,
          right: 75,
          zIndex: 1000,
          marginRight: "10px",
          marginTop: "10px",
        }}
        onClick={() => setExpanded(!expanded)}
      >
        {expanded ? (
          <FullscreenExitIcon width={"100px"} fontSize={"large"} />
        ) : (
          <FullscreenIcon width={"100px"} fontSize={"large"} />
        )}
      </Button>
      <MapContainer
        center={center}
        zoom={ZOOM_LEVEL}
        scrollWheelZoom={true}
        style={{
          height: "100%",
          minHeight: "85vh",
          width: "100%",
          position: "absolute",
        }}
      >
        <TileLayer
          attribution={mapConfig.maptiler.attr}
          url={mapConfig.maptiler.url}
        />

        <LayersControl position="topright">
          <LayersControl.Overlay checked name="RSSI">
            <LayerGroup>
              <MarkerClusterGroup
                showCoverageOnHover={true}
                disableClusteringAtZoom={18}
              >
                {sensorRSSI.map((marker: any) => {
                  return (
                    <Marker
                      key={marker.id}
                      icon={marker.icon}
                      position={marker.latLng}
                    >
                      <Popup>
                        {/* <b>Name:</b> {marker.name} <br />
                        <b>Owner:</b> {marker.owner} <br />
                        <b>Last reported:</b> {marker.prettyDate} <br />
                        <b>RSSI:</b> {marker.rssi} <LegendIcon Color={marker.rssiMetricColor}/> <br />
                        <b>SNR:</b> {marker.snr} <LegendIcon Color={marker.snrMetricColor}/> <br />
                        <b>SpreadingFactor:</b> {marker.spreadingFactor} <br /> */}
                        {/* {(marker.historicRSSIvalueMap !== undefined )? 
                        <MetricLineChart metricType="rssi" markerModel={marker} dataValues={[...marker.historicRSSIvalueMap.values()]} labels={[...marker.historicRSSIvalueMap.keys()]} />
                        : <></>} */}
                        <TabPopup markerProp={marker} />
                      </Popup>
                    </Marker>
                  );
                })}
              </MarkerClusterGroup>
            </LayerGroup>
          </LayersControl.Overlay>
          <LayersControl.Overlay name="SNR">
            <LayerGroup>
              <MarkerClusterGroup
                showCoverageOnHover={true}
                disableClusteringAtZoom={18}
              >
                {sensorSNR.map((marker: any) => {
                  return (
                    <Marker
                      key={marker.id}
                      icon={marker.icon}
                      position={marker.latLng}
                    >
                      <Popup>
                        <b>Name:</b> {marker.name} <br />
                        <b>Owner:</b> {marker.owner} <br />
                        <b>Last reported:</b> {marker.prettyDate} <br />
                        <b>RSSI:</b> {marker.rssi}{" "}
                        <LegendIcon Color={marker.rssiMetricColor} /> <br />
                        <b>SNR:</b> {marker.snr}{" "}
                        <LegendIcon Color={marker.snrMetricColor} /> <br />
                        <b>SpreadingFactor:</b> {marker.spreadingFactor} <br />
                      </Popup>
                    </Marker>
                  );
                })}
              </MarkerClusterGroup>
            </LayerGroup>
          </LayersControl.Overlay>
          <LayersControl.Overlay checked name="Gateway">
            <LayerGroup>
              <GeoJSON
                data={gatewayData as GeoJsonObject}
                pointToLayer={setGatewayMarker}
                onEachFeature={onEachFeatureOfGatewayGeoJson}
              ></GeoJSON>
            </LayerGroup>
          </LayersControl.Overlay>
        </LayersControl>

        <Legend />
      </MapContainer>
    </div>
  );
}

export default MapPage;
