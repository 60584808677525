import { API, graphqlOperation } from "aws-amplify";
import { listDimDocks30s } from "../../graphql/queries";

export const ServiceAPI = {
  async getServiceByServiceName(serviceName: string) {
    const queryVariables = {
      limit: 5000000,
      filter: {
        pk: {
          eq: `SERVICE`,
        },
        sk: {
          contains: `SERVICE`,
        },
        ServiceName: {
          eq: serviceName,
        },
        IsDeleted: {
          ne: true,
        },
      },
    };
    return new Promise(async (resolve, reject) => {
      try {
        const result = await API.graphql(
          graphqlOperation(listDimDocks30s, queryVariables)
        );
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  },
};
