import { css } from "@emotion/react";
import styled from "@emotion/styled";
import Theme from "../../theme/Theme";

// Define CSS styles using the `css` function
const commonStyles = css`
  h1 {
    font-size: 1.5rem;
    padding: 1.5rem 0 0 0;
    margin: 0;
  }

  .authenticator-container {
    margin-top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .forgot-pass-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }

  .forgot-pass-btn {
    color: ${Theme.token.colorPrimary};
  }

  .forgot-pass-btn:hover {
    color: #4aaeff;
    background-color: white;
  }

  .amplify-label {
    text-transform: uppercase;
    font-weight: 700;
    font-size: small;
    opacity: 80%;
  }

  .amplify-button--primary {
    padding: 0.5rem 2rem;
    background-color: ${Theme.token.colorPrimary};
  }

  .amplify-button--primary:hover {
    background-color: ${Theme.token.colorPrimary};
  }

  .amplify-button--small {
    color: ${Theme.token.colorPrimary};
  }

  .amplify-button--small:hover {
    color: #4aaeff;
    background-color: white;
  }

  .amplify-alert--error {
    border-radius: 4px;
    background-color: #fff2f0;
    border: 1px solid #ffccc7;
  }

  .amplify-alert__body {
    color: rgba(0, 0, 0, 0.88);
  }

  .amplify-alert__dismiss:hover {
    background-color: #fff2f0;
    color: hsl(0, 100%, 20%);
  }

  [data-amplify-authenticator] [data-amplify-router] {
    background-color: transparent;
    border-color: ${Theme.token.navBorderPrimary};
    border-width: ${Theme.app.borderWidth};
    border-style: solid;
    border-radius: ${Theme.app.borderRadius};
  }
`;

export const LoginStyles = styled.div`
  ${commonStyles};
`;
