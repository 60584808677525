export const getUserInformation = /* GraphQL */ `
  query GetUserInformation($pk: String!, $sk: String!) {
    getDimDocks30(pk: $pk, sk: $sk) {
      pk
      sk
      Username
      IsDeleted
    }
  }
`;

export const getServiceListInformation = /* GraphQL */ `
  query GetServiceListInformation(
    $filter: TableDimDocks30FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDimDocks30s(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        pk
        sk
        CreatedOn
        ModifiedOn
        CreatedBy
        ModifiedBy
        ServiceId
        ServiceName
        ServiceUrl
        ServiceType
        ServiceDescription
        IsServiceActive
        DoesServiceHaveChecks
        ServiceFormTemplate
        ServiceTemplateValues
        CheckTemplateValue
        HasSupersededRoles
        AssociatedRoles
        OrganizationLogoUrl
      }
      nextToken
    }
  }
`;

export const getRolesListInformation = /* GraphQL */ `
  query GetRolesList(
    $filter: TableDimDocks30FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDimDocks30s(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        pk
        sk
        RoleId
        RoleName
        RoleDescription
        IsRoleActive
        CreatedOn
        ModifiedOn
      }
      nextToken
    }
  }
`;

export const getAllNotificationsForUser = /* GraphQL */ `
  query ListDimDocks(
    $filter: TableDimDocks30FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDimDocks30s(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        NotificationId
        NotificationMessage
        HasNotificationBeenRead
      }
      nextToken
    }
  }
`;

export const getUserRoleInformation = /* GraphQL */ `
  query GetUserRoleInformation(
    $filter: TableDimDocks30FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDimDocks30s(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        pk
        sk
        RoleName
        ServiceId
      }
      nextToken
    }
  }
`;

export const getOrganizationListQuery = /* GraphQL */ `
  query GetOrganizationList(
    $filter: TableDimDocks30FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDimDocks30s(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        pk
        sk
        CreatedOn
        ModifiedOn
        CreatedBy
        ModifiedBy
        OrganizationId
        OrganizationName
        OrganizationDescription
        OrganizationBranding
        OrganizationLogoUrl
        OrganizationUrl
        AssociatedUsers
        IsOrganizationActive
        LastActiveOrganization
        PinnedOrganization
      }
      nextToken
    }
  }
`;

export const getOrganizationProjectsList = /* GraphQL */ `
  query GetOrganizationProjectsList(
    $filter: TableDimDocks30FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDimDocks30s(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        pk
        sk
        CreatedOn
        ModifiedOn
        CreatedBy
        ModifiedBy
        ProjectId
        ProjectName
        ProjectDescription
        IsProjectActive
        ProjectStartDate
        ProjectEndDate
        PhaseId
        PhaseName
        IsPhaseActive
        SectorId
        SectorName
        IsSectorActive
        ClientId
        ClientName
        IsClientActive
        RegionId
        RegionName
        IsRegionActive
        AssociatedUsers
      }
      nextToken
    }
  }
`;

export const getOrganizationUsersQuery = /* GraphQL */ `
  query GetOrganizationUser(
    $filter: TableDimDocks30FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDimDocks30s(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        pk
        sk
        CreatedOn
        ModifiedOn
        IsUserActive
        Username
        UserId
        RoleId
        AssociatedRoles
        ServiceId
        IsDeleted
      }
      nextToken
    }
  }
`;

export const getProjectAssignedServices = /* GraphQL */ `
  query GetProjectAssignedServices(
    $filter: TableDimDocks30FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDimDocks30s(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        OrganizationId
        CreatedOn
        ModifiedOn
        CreatedBy
        ModifiedBy
        ProjectId
        ServiceId
        ServiceTemplateValues
        pk
        sk
      }
      nextToken
    }
  }
`;

export const getSectorsData = /* GraphQL */ `
  query GetSectorsData(
    $filter: TableDimDocks30FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDimDocks30s(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        CreatedOn
        ModifiedOn
        CreatedBy
        ModifiedBy
        SectorId
        SectorName
        SectorDescription
        IsSectorActive
        pk
        sk
      }
      nextToken
    }
  }
`;

export const getPhasesData = /* GraphQL */ `
  query GetPhasesData(
    $filter: TableDimDocks30FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDimDocks30s(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        CreatedOn
        ModifiedOn
        CreatedBy
        ModifiedBy
        PhaseId
        PhaseName
        IsPhaseActive
        PhaseDescription
        pk
        sk
      }
      nextToken
    }
  }
`;

export const getClientsData = /* GraphQL */ `
  query GetClientsData(
    $filter: TableDimDocks30FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDimDocks30s(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        CreatedOn
        ModifiedOn
        CreatedBy
        ModifiedBy
        ClientId
        ClientName
        IsClientActive
        ClientDescription
        pk
        sk
      }
      nextToken
    }
  }
`;

export const getRegionsData = /* GraphQL */ `
  query getRegionData(
    $filter: TableDimDocks30FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDimDocks30s(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        CreatedOn
        ModifiedOn
        CreatedBy
        ModifiedBy
        RegionId
        RegionName
        RegionDescription
        IsRegionActive
        RegionLatitude
        RegionLongitude
        pk
        sk
      }
      nextToken
    }
  }
`;

export const getChecksData = /* GraphQL */ `
  query GetCheckData(
    $filter: TableDimDocks30FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDimDocks30s(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        CreatedOn
        ModifiedOn
        CreatedBy
        ModifiedBy
        CheckId
        CheckName
        CheckDescription
        CheckFileType
        IsCheckActive
        AssociatedSectors
        CheckDataType
        pk
        sk
      }
      nextToken
    }
  }
`;

export const getServiceIdsByOrganizationId = /* GraphQL */ `
  query GetServiceIdsByOrganizationId(
    $filter: TableDimDocks30FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDimDocks30s(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        pk
        sk
      }
      nextToken
    }
  }
`;

export const getServiceTemplateValuesByOrganizationId = /* GraphQL */ `
  query GetServiceTemplateValuesByOrganizationId($pk: String!, $sk: String!) {
    getDimDocks30(pk: $pk, sk: $sk) {
      ServiceTemplateValues
    }
  }
`;

export const getUserRole = /* GraphQL */ `
  query GetUserRole(
    $filter: TableDimDocks30FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDimDocks30s(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        pk
        sk
        RoleId
        RoleName
        AssociatedRoles
      }
      nextToken
    }
  }
`;

export const getServiceProject = /* GraphQL */ `
  query GetServiceProject(
    $filter: TableDimDocks30FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDimDocks30s(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        pk
        sk
        CreatedOn
        ModifiedOn
        CreatedBy
        ModifiedBy
      }
      nextToken
    }
  }
`;

export const getServiceUser = /* GraphQL */ `
  query GetServiceUser(
    $filter: TableDimDocks30FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDimDocks30s(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        pk
        sk
        UserId
        CreatedOn
        ModifiedOn
        CreatedBy
        ModifiedBy
      }
      nextToken
    }
  }
`;

export const listDataSet3dviewers = /* GraphQL */ `
  query ListDataSet3dviewers(
    $filter: ModelDataSet3dviewerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDataSet3dviewers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        DataSet
        Priority
        FmeServerFilePath
        SharedData
        MinTileSize
        FmeServerCached
        Resolution
        createdAt
        updatedAt
        Type {
          id
          Type
          FileTypes
          RequestString
          Workspace
          createdAt
          updatedAt
        }
        Projects {
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const getProject3dviewerByNameRelative = /* GraphQL */ `
  query GetProject3dviewerByNameRelative(
    $ProjectName: String
    $sortDirection: ModelSortDirection
    $filter: ModelProject3dviewerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getProject3dviewerByName(
      ProjectName: $ProjectName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ProjectName
        Origin_X
        Bounds
        StreamingURL
        Origin_Y
        createdAt
        updatedAt
        DataSets {
          items {
            id
            dataSet {
              id
              DataSet
              Type {
                id
                Type
              }
              Priority
              FmeServerFilePath
              SharedData
              MinTileSize
              FmeServerCached
              Resolution
            }
          }
          nextToken
        }
        CoordinateSystem {
          id
          CoordinateSystem
          XMAX
          YMAX
          XMIN
          YMIN
          EPSG
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;

export const updateProject3dviewer = /* GraphQL */ `
  mutation UpdateProject3dviewer(
    $input: UpdateProject3dviewerInput!
    $condition: ModelProject3dviewerConditionInput
  ) {
    updateProject3dviewer(input: $input, condition: $condition) {
      id
      ProjectName
      Origin_X
      Bounds
      Origin_Y
      Application
      StreamingURL
      createdAt
      updatedAt
    }
  }
`;
