import {
    Chart as ChartJS,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale
} from 'chart.js';
import 'chartjs-adapter-date-fns'; // This date adapter is needed otherwise ChartJs won't recognize Time scale
import { Line } from 'react-chartjs-2';

ChartJS.register(
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale // It is needed to work with DateTime fields in the axis
);

export function MetricLineChart(props: any) {

    const values = props.dataValues.map((arr: any) => {
        return {
            x: new Date(arr[0]),
            y: arr[1]
        }
    });

    const options = {
        responsive: true,
        plugins: {
            legend: { display: false },
            title: {
                display: true,
                text: props.markerModel.name,
            }

        },
        scales: {
           x: {
                type: 'time' as const, // as const is essential 
                time: {
                    parser: 'MMM d',
                    tooltipFormat: 'MMM d HH:mm',
                    unit: 'day' as const,// as const is essential
                    unitStepSize: 1,
                    displayFormats: {
                        'day': 'MMM d'
                    }
                }
            }
        }

    };

    function getPointColor(context: any) {
        let point = context.dataset.data[context.dataIndex];

        if (point !== undefined) {
            return props.metricType === "rssi" ? props.markerModel.getRSSIColorByValue(point) : props.markerModel.getSNRColorByValue(point);
        }
        else
            return "gray";
    }

    const data = {
        datasets: [
            {
                fill: false, 
                label: props.markerModel.name,
                backgroundColor: "rgba(0,0,255,1.0)",
                data: values,
                pointRadius: 3,
                pointBackgroundColor: (ctx: any) => getPointColor(ctx),
                tension:0.4
            }
        ]

    };

    return (
        <Line options={options} data={data} />
    );
};